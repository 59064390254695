import React from 'react'
import { Switch, Route } from 'react-router-dom'

import List from './List'
import Create from './Create'
import Edit from './Edit'

const Routes = () => (
  <Switch>
    <Route path="/Intro-videos/create" component={Create} />
    <Route path="/intro-videos/:id/edit" component={Edit} />
    <Route path="/intro-videos" component={List} />
  </Switch>
)

export default Routes
