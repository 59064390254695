import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  class Update extends React.Component {
    componentWillMount() {
      const { getVideosOptions, getChannels, getVideo, match } = this.props
      getVideosOptions()
      getChannels({ params: { status: 2 } })
      getVideo(match.params.id)
    }

    onSuccess = res => {
      const { history } = this.props
      Toaster.success(res.data.message)
      history.push('/videos')
    }

    handleSubmit = values => {
      const { updateVideo, match } = this.props
      updateVideo(values, match.params.id, { onFulfilled: this.onSuccess })
    }

    onCancel = () => {
      const { history } = this.props
      history.goBack()
    }

    onChannesListChange = data => {
      const { getChannels } = this.props
      getChannels({ params: { search: data, status: 2 } })
    }

    render() {
      const { fetching, fetch_channels, error, video, channels } = this.props
      return (
        <WrappedComponent
          {...this.props}
          channels={channels.channels}
          onChannesListChange={this.onChannesListChange}
          fetch_channels={fetch_channels}
          fetching={fetching}
          error={error}
          handleSubmit={this.handleSubmit}
          video={video.video}
          onCancel={this.onCancel}
        />
      )
    }
  }

  Update.propTypes = {
    t: PropTypes.func.isRequired,
    getVideosOptions: PropTypes.func.isRequired,
    getVideo: PropTypes.func.isRequired,
    updateVideo: PropTypes.func.isRequired,
    video: PropTypes.object,
    match: PropTypes.object,
    fetching: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.object.isRequired,
    getChannels: PropTypes.func.isRequired,
    fetch_channels: PropTypes.bool,
    channels: PropTypes.object,
  }

  Update.defaultProps = {
    video: {},
    match: {},
    fetching: false,
    error: null,
    fetch_channels: false,
    channels: {},
  }

  const mapStateToProp = ({ api }) => ({
    options: api.videos_options || {},
    video: api.video || {},
    loading: api.requests.video.put.loading,
    fetching: api.requests.video.put.fetching,
    fetching_video: api.requests.video.get.fetching,
    error: api.requests.video.put.error,
    channels: api.channels || {},
    fetch_channels: api.requests.channels.get.fetching,
  })

  const mapDispatchToProps = dispatch => ({
    updateVideo: (data, id, props = {}) =>
      dispatch({
        type: 'UPDATE_VIDEO',
        data: { video: data },
        vars: { id },
        ...props,
      }),
    getVideosOptions: props =>
      dispatch({
        type: 'GET_VIDEOS_OPTIONS',
        ...props,
      }),
    getVideo: (id, props) =>
      dispatch({
        type: 'GET_VIDEO',
        vars: { id },
        ...props,
      }),
    getChannels: props =>
      dispatch({
        type: 'GET_CHANNELS',
        ...props,
      }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Update))
}

export default HOC
