import React from 'react'
import { HashRouter } from 'react-router-dom'

import RootRouter from './routers/RootRouter'

import 'toastr/build/toastr.css'
import './app.css'

const App = () => (
  <HashRouter>
    <RootRouter />
  </HashRouter>
)

export default App
