import React from 'react'
import { Grid } from 'semantic-ui-react'
import Search from 'molecules/Search'
import Dropdown from 'molecules/semantic-ui/Dropdown'

const stateOptions = [
  { key: 'all', value: 'all', text: 'All Videos' },
  { key: '1', value: '1', text: 'Active' },
  { key: '2', value: '2', text: 'In Active' },
]
const Filters = ({
  disabled,
  handleFilters,
  options,
  channel_options,
  filters,
}) => (
  <React.Fragment>
    <Grid.Column computer={4} mobile={16}>
      <Search
        disabled={disabled}
        fluid
        size="small"
        icon="search"
        iconPosition="left"
        placeholder="Search by channel name..."
        onTyping={value => handleFilters('search', value)}
      />
    </Grid.Column>
    <Grid.Column computer={4} mobile={16} centered verticalAlign="middle">
      <div
        style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}
      >
        <div style={{ marginRight: 10 }}>Filter By</div>
        <Dropdown
          selection
          clearable
          fluid
          disabled={disabled}
          placeholder="Status"
          labelKey="name"
          valueKey="id"
          options={options.statuses}
          onChange={(e, data) => handleFilters('status', data.value)}
        />
      </div>
    </Grid.Column>
    <Grid.Column computer={3} mobile={16} centered verticalAlign="middle">
      <Dropdown
        selection
        clearable
        fluid
        search
        disabled={disabled}
        placeholder="Category"
        labelKey="title"
        valueKey="id"
        options={options.categories}
        onChange={(e, data) => handleFilters('category_id', data.value)}
      />
    </Grid.Column>
    <Grid.Column computer={3} mobile={16} centered verticalAlign="middle">
      <Dropdown
        selection
        clearable
        search
        fluid
        disabled={disabled}
        placeholder="Age Group"
        labelKey="title"
        valueKey="id"
        options={options.age_groups}
        onChange={(e, data) => handleFilters('age_group_id', data.value)}
      />
    </Grid.Column>
    <Grid.Column computer={2} mobile={16} centered verticalAlign="middle">
      <Dropdown
        selection
        fluid
        disabled={disabled}
        value={filters.direction}
        placeholder="Sort"
        labelKey="title"
        valueKey="key"
        options={[
          { key: 'desc', title: 'Newest' },
          { key: 'asc', title: 'Oldest' },
        ]}
        onChange={(e, data) => handleFilters('direction', data.value)}
      />
    </Grid.Column>

    {/*
    <Grid.Column width={3} centered verticalAlign="middle" mobile={16}>
      <Dropdown
        selection
        clearable
        disabled={disabled}
        placeholder="Category"
        labelKey="title"
        valueKey="id"
        options={channel_options.categories}
        onChange={(e, data) => handleFilters('category_id', data.value)}
      />
    </Grid.Column>
    */}
  </React.Fragment>
)

export default Filters
