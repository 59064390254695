import React from 'react'
import PropTypes from 'prop-types'

const UploadFiles = ({ multiple, accept, handleChange, className }) => (
  <input
    type="file"
    accept={accept}
    onChange={handleChange}
    multiple={multiple}
    className={className}
  />
)

UploadFiles.propTypes = {
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

UploadFiles.defaultProps = {
  multiple: false,
  accept: '',
  className: '',
}
export default UploadFiles
