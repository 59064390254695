import i18next from 'i18next'

const menuOptions = [
  {
    url: '/dashboard',
    text: i18next.t('Dashboard'),
    icon: 'compass outline',
  },
  {
    url: '/channels',
    text: i18next.t('Tuby Channels'),
    icon: 'caret square right outline',
  },
  {
    url: '/custom-channels',
    text: i18next.t('Custom Channels'),
    icon: 'caret square up outline',
  },
  {
    url: '/videos',
    text: i18next.t('Tuby Videos'),
    icon: 'video',
  },
  {
    url: '/notifications',
    text: i18next.t('Notifications'),
    icon: 'bell outline',
  },
  {
    url: '/pages',
    text: i18next.t('Pages'),
    icon: 'clone',
  },
  {
    url: '/ip-whitelists',
    text: i18next.t('IP Whitelists'),
    icon: 'unlock alternate',
  },
  {
    url: '/themes',
    text: i18next.t('Themes'),
    icon: 'theme',
  },
  {
    url: '/intro-videos',
    text: i18next.t('Intro Videos'),
    icon: 'play circle outline',
  },
  {
    url: '/users',
    text: i18next.t('Users'),
    icon: 'user outline',
  },
  {
    url: '/recommendation-config',
    text: i18next.t('Recommendation Config'), // Configuration
    icon: 'setting',
  },
]

export { menuOptions }
