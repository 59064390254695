import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { Icon } from 'semantic-ui-react'
import LoginTemplate from 'templates/Login'
import Header from '../../organisms/Header'

const Login = ({ t, i18n, onSubmit, fetching, error }) => {
  const username = t('username')
  const password = t('Password')
  return (
    <div className="tu-container">
      <Header />
      <div className="tu-form-container">
        <LoginTemplate
          fields={[
            {
              name: 'username',
              placeholder: username,
              type: 'text',
              icon: <Icon name="user" />,
              iconPosition: i18n.language === 'en' ? 'left' : 'right',
              size: 'small',
              className: 'Change',
            },
            {
              name: 'password',
              placeholder: password,
              type: 'password',
              icon: <Icon name="lock" />,
              iconPosition: i18n.language === 'en' ? 'left' : 'right',
              size: 'small',
              className: 'Change',
            },
          ]}
          label={t('Log In')}
          onSubmit={onSubmit}
          ButtonProps={{
            basic: true,
            color: 'purple',
            size: 'small',
            loading: fetching,
            disabled: fetching,
          }}
          error={error}
        />
      </div>
    </div>
  )
}

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.bool,
}

Login.defaultProps = {
  error: false,
}

export default translate('translations')(Login)
