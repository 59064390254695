import React from 'react'
import PropTypes from 'prop-types'

const HOC = WrappedComponent => {
  const UploadFiles = ({ multiple, accept, onDone, className }) => {
    const handleFileSize = bytes => {
      let temp = bytes
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
      for (let i = 0; i < sizes.length; i += 1) {
        if (temp <= 1024) {
          return `${temp}  ${sizes[i]}`
        }
        temp = parseFloat(temp / 1024).toFixed(2)
      }
      return `${temp}  P`
    }

    const handleChange = e => {
      // get the files
      const { files } = e.target
      // Process each file
      const allFiles = []

      for (let i = 0; i < files.length; i += 1) {
        const file = files[i]

        // Make new FileReader
        const reader = new FileReader()

        // Convert the file to base64 text
        reader.readAsDataURL(file)

        // on reader load somthing...
        reader.onload = () => {
          const fileInfo = {
            name: file.name,
            type: file.type,
            size: handleFileSize(file.size),
            file: reader.result,
          }

          // Push it to the state
          allFiles.push(fileInfo)

          // If all files have been proceed
          if (allFiles.length === files.length) {
            // Apply Callback function
            if (multiple) {
              onDone(allFiles)
            } else {
              onDone(allFiles[0])
            }
          }
        } // reader.onload
      } // for
    }
    return (
      <WrappedComponent
        accept={accept}
        handleChange={handleChange}
        className={className}
      />
    )
  }

  UploadFiles.propTypes = {
    multiple: PropTypes.bool,
    onDone: PropTypes.func.isRequired,
    className: PropTypes.string,
    accept: PropTypes.string,
  }
  UploadFiles.defaultProps = {
    multiple: false,
    className: '',
    accept: '',
  }

  return UploadFiles
}

export default HOC
