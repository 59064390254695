import React from 'react'
import PropTypes from 'prop-types'
import AppContainer from 'templates/AppContainer'
import Form from './Form'

const Create = ({ handleSubmit, onCancel, error, fetching, options, t }) => (
  <AppContainer header={t('Add Intro Video')}>
    <Form
      t={t}
      options={options}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      errorMessage={error}
      fetching={fetching}
      isCreate
    />
  </AppContainer>
)

export default Create

Create.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.object,
}

Create.defaultProps = {
  fetching: false,
  error: null,
  options: {},
}
