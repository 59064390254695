import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  class Update extends React.Component {
    componentWillMount() {
      const { getThemeOptions, getTheme, match, history } = this.props
      //  getThemeOptions()
      if (match.params.id == 1) {
        return history.push('/themes')
      }
      getTheme(match.params.id)
    }

    onSuccess = res => {
      const { history, getThemes } = this.props
      Toaster.success(res.data.message)
      getThemes()
      history.goBack()
    }

    handleSubmit = values => {
      const { updateTheme, match } = this.props
      updateTheme(values, match.params.id, { onFulfilled: this.onSuccess })
    }

    onCancel = () => {
      const { history } = this.props
      history.goBack()
    }

    render() {
      const { fetching, error } = this.props
      return (
        <WrappedComponent
          {...this.props}
          fetching={fetching}
          error={error}
          handleSubmit={this.handleSubmit}
          onCancel={this.onCancel}
        />
      )
    }
  }

  Update.propTypes = {
    t: PropTypes.func.isRequired,
    getThemeOptions: PropTypes.func.isRequired,
    getTheme: PropTypes.func.isRequired,
    getThemes: PropTypes.func.isRequired,
    updateTheme: PropTypes.func.isRequired,
    theme: PropTypes.object,
    match: PropTypes.object,
    fetching: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.object.isRequired,
  }

  Update.defaultProps = {
    theme: {},
    match: {},
    fetching: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    options: api.themes_options || {},
    theme: api.theme || {},
    loading: api.requests.theme.put.loading,
    fetching: api.requests.theme.put.fetching,
    fetching_theme: api.requests.theme.get.fetching,
    error: api.requests.theme.put.error,
  })

  const mapDispatchToProps = dispatch => ({
    updateTheme: (data, id, props = {}) =>
      dispatch({
        type: 'UPDATE_THEME',
        data: { theme: data },
        vars: { id },
        ...props,
      }),
    getThemeOptions: props =>
      dispatch({
        type: 'GET_THEMES_OPTIONS',
        ...props,
      }),
    getTheme: (theme_id, props) =>
      dispatch({
        type: 'GET_THEME',
        vars: { theme_id },
        ...props,
      }),
    getThemes: props =>
      dispatch({
        type: 'GET_THEMES',
        ...props,
      }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Update))
}

export default HOC
