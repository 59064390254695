import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Button, Grid, Message, Dimmer, Loader } from 'semantic-ui-react'
import reduxForm from 'atoms/reduxForm'
import textInput from 'molecules/reduxFormFields/textInput'
import editor from 'molecules/reduxFormFields/editor'
import dropdownInput from 'molecules/reduxFormFields/dropdownInput'

const Form = ({
  handleSubmit,
  onCancel,
  validate,
  t,
  fetching,
  errorMessage,
}) => (
  <React.Fragment>
    {errorMessage && (
      <Message negative className="page-form--error-message">
        {errorMessage}
      </Message>
    )}
    <Grid doubling columns={2}>
      {fetching && (
        <Dimmer inverted active>
          <Loader size="medium">Processing...</Loader>
        </Dimmer>
      )}
      <Grid.Row>
        <Grid.Column width={16} className="page-form_field">
          <Field
            validate={[validate.required]}
            name="title_ar"
            title={t('Title (Arabic)')}
            component={textInput}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16} className="page-form_field">
          <Field
            validate={[validate.required]}
            name="title_en"
            title={t('Title (English)')}
            component={textInput}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16} className="page-form_field">
          <Field
            name="content_ar"
            title={t('Content (Arabic)')}
            component={editor}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16} className="page-form_field">
          <Field
            name="content_en"
            title={t('Content (English)')}
            component={editor}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="page-form_button">
        <Grid.Column width={16}>
          <Button primary disabled={fetching} onClick={handleSubmit}>
            {t('Save')}
          </Button>
          <Button color="grey" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </React.Fragment>
)

Form.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  validate: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  errorMessage: PropTypes.string,
}
Form.defaultProps = {
  fetching: false,
  errorMessage: null,
}

export default reduxForm({
  form: 'pages_form',
  enableReinitialize: true,
})(Form)
