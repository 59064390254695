import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader } from 'semantic-ui-react'
import omit from 'lodash/omit'
import AppContainer from 'templates/AppContainer'
import Form from '../Create/Form'

const formFields = data => {
  const values = omit(data, [])
  return values
}
const Update = ({
  handleSubmit,
  error,
  fetching,
  fetching_intro_video,
  options,
  intro_video,
  onCancel,
  t,
}) => (
  <AppContainer header={t('Update Intro Video')}>
    {fetching_intro_video && (
      <Dimmer inverted active>
        <Loader size="medium">Fetching Intro Video Data...</Loader>
      </Dimmer>
    )}
    <Form
      t={t}
      initialValues={formFields(intro_video)}
      options={options}
      onSubmit={handleSubmit}
      errorMessage={error}
      fetching={fetching}
      onCancel={onCancel}
    />
  </AppContainer>
)

export default Update

Update.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  fetching_intro_video: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.object,
  intro_video: PropTypes.object,
}

Update.defaultProps = {
  fetching: false,
  fetching_intro_video: false,
  error: null,
  options: {},
  intro_video: {},
}
