export function getPageURL(page) {
  if (!page) return ''
  const port = window.location.port && `:${window.location.port}`
  const protocol = window.location.protocol
  const hash = window.location.hash && '#/'
  const host = window.location.hostname
  const url = `${protocol}//${host}${port}/${hash}about/${page
    .toLowerCase()
    .replace(' ', '_')}`
  return url
}
