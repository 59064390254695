import React from 'react'
import PropTypes from 'prop-types'

const Color = ({ color, name }) => {
  const block = {
    width: '170px',
    background: `${color}`,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px',
    borderRadius: '6px',
    position: 'relative',
  }

  const aab = {
    width: '0px',
    height: '0px',
    borderStyle: 'solid',
    borderWidth: '0px 10px 10px',
    // borderColor,
    // borderColor: 'transparent transparent rgb(195, 36, 120)',
    borderColor: `transparent transparent ${color}`,
    position: 'absolute',
    top: '-10px',
    left: '50%',
    marginLeft: '-10px',
  }

  const aac = {
    height: '110px',
    background: `${color}`,
    // background: 'rgb(195, 36, 120)',
    borderRadius: '6px 6px 0px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }

  const aacc = {
    fontSize: '18px',
    color: 'rgb(255, 255, 255)',
    position: 'relative',
  }

  const aaccc = {
    fontSize: '18px',
    color: 'rgb(255, 255, 255)',
    position: 'relative',
  }

  const aacccc = {
    fontSize: '18px',
    color: 'rgb(255, 255, 255)',
    position: 'relative',
  }

  const input = {
    width: ' 100%',
    fontSize: '12px',
    color: 'rgb(102, 102, 102)',
    border: '0px',
    outline: 'none',
    height: '22px',
    boxShadow: 'rgb(221, 221, 221) 0px 0px 0px 1px inset',
    borderRadius: '4px',
    padding: '0px 7px',
    boxSizing: 'border-box',
  }

  return (
    <div className="block-picker" style={block}>
      <div style={aab} />
      <div style={aac}>
        <div
          // className="aacc"
          style={aacc}
        >
          {name}
        </div>
      </div>
      <div style={aaccc}>
        <div style={aacccc}>
          <input spellCheck="false" value={name} style={input} />
        </div>
      </div>
    </div>
  )
}

export default Color

Color.propTypes = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
