import React from 'react'
import { Switch, Route } from 'react-router-dom'

import List from './List'
import Create from './Create'
import Update from './Update'
import Show from './Show'

const Routes = () => (
  <Switch>
    <Route exact path="/pages" component={List} />
    <Route exact path="/pages/:id/show" component={Show} />
    <Route exact path="/pages/:id/edit" component={Update} />
    <Route exact path="/pages/create" component={Create} />
  </Switch>
)

export default Routes
