import React from 'react'
import PropTypes from 'prop-types'

const ListItem = ({ header, description }) => (
  <div className="theme-item">
    <strong>{header}</strong>
    <spam>{description}</spam>
  </div>
)

export default ListItem

ListItem.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
