import React, { Component } from 'react'
import { connect } from 'react-redux'
import swal from 'sweetalert'

const HOC = WrappedComponent => {
  class Videos extends Component {
    state = {
      filters: {
        status: null,
        search: null,
        page: 1,
        limit: 32,
        channel_id: null,
        recommended: null,
      },
    }

    handleFilters = (key, value) => {
      const { filters } = this.state
      filters[key] = value
      filters.page = key === 'page' ? value : 1
      this.setState({ filters })
      this.loadVideos(filters)
    }

    handleDelete = (video_id, status) => {
      const { deleteVideo } = this.props

      swal({
        title: 'Are you sure?',
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          catch: {
            text: 'Yes, Delete',
            closeModal: false,
          },
        },
      })
        .then(confirm => {
          if (!confirm) throw null
          return new Promise((resolve, reject) => {
            const onFulfilled = respo => {
              resolve(respo.data.message)
              this.loadVideos()
            }

            const onFaild = res => {
              reject(res)
            }
            deleteVideo(video_id, { onFulfilled, onFaild })
          })
        })
        .then(res => {
          swal({
            title: 'Deleted!',
            text: res,
            icon: 'success',
            button: false,
            timer: 2000,
          })
        })
        .catch(err => {
          if (err) {
            swal({
              title: 'Error',
              text: err,
              icon: 'error',
              button: false,
            })
          } else {
            swal.stopLoading()
            swal.close()
          }
        })
    }

    loadVideos = (filters = this.state.filters) => {
      const { getVideos } = this.props
      getVideos({ params: filters })
    }

    handleChannelsSearch = data => {
      const { getChannels } = this.props
      getChannels({ params: { search: data } })
    }

    componentDidMount() {
      this.loadVideos()
      this.props.getVideoOptions()
      this.props.getChannels()
      // this.props.getOptions()
    }

    onChannesListChange = data => {
      const { getChannels } = this.props
      getChannels({ params: { search: data } })
    }

    render() {
      const videos = this.props.videos.videos || []
      return (
        <WrappedComponent
          {...this.props}
          videos={videos}
          filters={this.state.filters}
          pagination={this.props.videos.pagination || {}}
          noVideos={!this.props.fetching && videos.length <= 0}
          handleFilters={this.handleFilters}
          handleDelete={this.handleDelete}
          channels={this.props.channels.channels || []}
          channelsSearch={this.handleChannelsSearch}
        />
      )
    }
  }

  const mapStateToProps = ({ api }) => ({
    videos: api.videos || {},
    fetching: api.requests.videos.get.fetching,
    loading: api.requests.videos.get.loading,
    error: api.requests.videos.get.error,
    options: api.videos_options || {},
    channels: api.channels || {},
    channel_options: api.options || {},
    fetch_channels: api.requests.channels.get.fetching,
  })

  const mapDispatchToProps = dispatch => ({
    getVideos: props =>
      dispatch({
        type: 'GET_VIDEOS',
        ...props,
      }),
    getVideoOptions: props =>
      dispatch({
        type: 'GET_VIDEOS_OPTIONS',
      }),
    deleteVideo: (video_id, props) =>
      dispatch({
        type: 'DELETE_VIDEO',
        vars: { video_id },
        ...props,
      }),
    getOptions: props =>
      dispatch({
        type: 'GET_OPTIONS',
        ...props,
      }),
    getChannels: props =>
      dispatch({
        type: 'GET_CHANNELS',
        ...props,
      }),
  })
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Videos)
}

export default HOC
