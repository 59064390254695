import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  class Update extends React.Component {
    componentWillMount() {
      const { getPage, match } = this.props
      getPage(match.params.id)
    }

    onSuccess = res => {
      const { history } = this.props
      Toaster.success(res.data.message)
      history.push('/pages')
    }

    handleSubmit = values => {
      const { updatePage, match } = this.props
      updatePage(values, match.params.id, { onFulfilled: this.onSuccess })
    }

    onCancel = () => {
      const { history } = this.props
      history.goBack()
    }

    render() {
      const { page } = this.props
      return (
        <WrappedComponent
          {...this.props}
          page={page.about_app}
          handleSubmit={this.handleSubmit}
          onCancel={this.onCancel}
        />
      )
    }
  }

  Update.propTypes = {
    getPage: PropTypes.func.isRequired,
    updatePage: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    page: PropTypes.object,
  }

  Update.defaultProps = {
    page: {},
  }

  const mapStateToProp = ({ api }) => ({
    page: api.page || {},
    loading: api.requests.page.put.loading,
    fetching: api.requests.page.put.fetching,
    fetching_page: api.requests.page.get.fetching,
    error: api.requests.page.put.error,
  })

  const mapDispatchToProps = dispatch => ({
    updatePage: (data, id, props = {}) =>
      dispatch({
        type: 'UPDATE_PAGE',
        data: { about_app: data },
        vars: { id },
        ...props,
      }),
    getPage: (id, props) =>
      dispatch({
        type: 'GET_PAGE',
        vars: { id },
        ...props,
      }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Update))
}

export default HOC
