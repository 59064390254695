import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { validations } from './validations'

const HOCLoginBox = WrappedComponent => {
  class LoginBox extends Component {
    state = {
      values: {},
      errors: {},
    }

    handleSubmit = () => {
      const { values } = this.state
      const { onSubmit } = this.props
      onSubmit(values)
    }

    handleOnChange = (name, value) => {
      const { values } = this.state
      const { onChange, validate } = this.props
      const errors = validate(values)
      values[name] = value
      this.setState({ values, errors })
      onChange(values)
    }

    render() {
      const { errors } = this.state
      const { errors: propErrors } = this.props
      return (
        <WrappedComponent
          handleOnChange={this.handleOnChange}
          {...this.props}
          errors={propErrors || errors}
          handleSubmit={this.handleSubmit}
        />
      )
    }
  }

  LoginBox.propTypes = {
    validate: PropTypes.func,
    errors: PropTypes.object,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
  }

  LoginBox.defaultProps = {
    validate: validations,
    errors: null,
    onChange: () => {},
    onSubmit: () => {},
  }

  return LoginBox
}

export default HOCLoginBox
