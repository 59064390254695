import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Dropdown, Icon } from 'semantic-ui-react'
import { Cards, Card } from 'atoms/Cards'
import VideoCard from 'templates/VideoCard'
import Status from 'atoms/Status'

const VideosList = ({ videos, handleDelete }) => (
  <Cards gap="13px" cardWidth="230px">
    {videos.map(video => (
      <Card key={video.id}>
        <VideoCard
          image={video.thumbnail}
          title={video.title}
          date={`Published on ${moment(video.published_at).format(
            'MMM DD, YYYY',
          )}`}
          views={video.video_type_label}
          status={<Status name={video.status_label.toLowerCase()} />}
          video_id={video.youtube_id}
          actions={
            <Dropdown
              direction="left"
              icon={null}
              trigger={<Icon name="ellipsis horizontal" size="large" />}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="pencil alternate"
                  text="Edit"
                  as={Link}
                  to={`/videos/${video.id}/edit`}
                />
                <Dropdown.Item
                  icon="trash alternate outline"
                  text="Delete"
                  onClick={() => handleDelete(video.id)}
                />
              </Dropdown.Menu>
            </Dropdown>
          }
        />
      </Card>
    ))}
  </Cards>
)

export default VideosList
