import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader } from 'semantic-ui-react'
import omit from 'lodash/omit'
import AppContainer from 'templates/AppContainer'
import Form from './Form'

// const toInt = x => Math.floor(x * 100)

const formFields = data => {
  const values = omit(data, [])
  // values.trends_ratio = data.trends_ratio && toInt(data.trends_ratio) // parseInt
  // values.news_ratio = data.news_ratio && toInt(data.news_ratio)
  // values.videos_by_categories_ratio =    data.videos_by_categories_ratio && toInt(data.videos_by_categories_ratio)
  // values.videos_by_channels_ratio =    data.videos_by_channels_ratio && toInt(data.videos_by_channels_ratio)
  return values
}

const Update = ({
  handleSubmit,
  error,
  fetching,
  fetching_recommendation_config,
  options,
  recommendation_config,
  onCancel,
  t,
}) => (
  <AppContainer header={t(' Recommendation Configuration')}>
    {fetching_recommendation_config && (
      <Dimmer inverted active>
        <Loader size="medium">
          Fetching Recommendation Configuration Data...
        </Loader>
      </Dimmer>
    )}
    <Form
      t={t}
      initialValues={formFields(recommendation_config)}
      options={options}
      onSubmit={handleSubmit}
      errorMessage={error}
      fetching={fetching}
      onCancel={onCancel}
    />
  </AppContainer>
)

export default Update

Update.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  fetching_recommendation_config: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.object,
  recommendation_config: PropTypes.object,
}

Update.defaultProps = {
  fetching: false,
  fetching_recommendation_config: false,
  error: null,
  options: {},
  recommendation_config: {},
}
