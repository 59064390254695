import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  const Create = props => {
    const {
      fetching,
      error,
      history,
      createIPWhitelist,
      getIPWhitelists,
    } = props
    const handleOnClose = () => {
      history.goBack()
    }

    const onSuccess = res => {
      Toaster.success(res.data.message)
      getIPWhitelists({
        params: { limit: 12 },
      })
      history.goBack()
    }

    const handleSubmit = values => {
      createIPWhitelist(values, { onFulfilled: onSuccess })
    }

    return (
      <WrappedComponent
        fetching={fetching}
        error={error}
        onClose={handleOnClose}
        handleSubmit={handleSubmit}
        {...props}
      />
    )
  }

  Create.propTypes = {
    t: PropTypes.func.isRequired,
    getIPWhitelists: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.func.isRequired,
    createIPWhitelist: PropTypes.func.isRequired,
  }

  Create.defaultProps = {
    fetching: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    loading: api.requests.ip_whitelist.post.loading,
    fetching: api.requests.ip_whitelist.post.fetching,
    error: api.requests.ip_whitelist.post.error,
  })

  const mapDispatchToProps = dispatch => ({
    createIPWhitelist: (data, props = {}) =>
      dispatch({
        type: 'CREATE_IP_WHITELIST',
        data: { ipwhitelist: data },
        ...props,
      }),
    getIPWhitelists: (props = {}) =>
      dispatch({ type: 'GET_IP_WHITELISTS', ...props }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Create))
}

export default HOC
