import React from 'react'
import { Image, Header } from 'semantic-ui-react'

import logo from '../../../asset/images/logo.png'

const Home = () => (
  <div style={{ textAlign: 'center' }}>
    <div style={{ width: '150px', margin: 'auto', marginTop: 100 }}>
      <Image src={logo} size="small" />
    </div>
    <Header as="h3">Tuby Admin Panel</Header>
    v1.0.0
  </div>
)

export default Home
