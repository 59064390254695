import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  class Update extends React.Component {
    componentWillMount() {
      const { getChannelOptions, getChannel, match } = this.props
      getChannelOptions()
      getChannel(match.params.id)
    }

    onSuccess = res => {
      const { history, getChannels } = this.props
      Toaster.success(res.data.message)
      getChannels()
      history.goBack()
    }

    handleSubmit = values => {
      const { updateChannel, match } = this.props
      updateChannel(values, match.params.id, { onFulfilled: this.onSuccess })
    }

    onCancel = () => {
      const { history } = this.props
      history.goBack()
    }

    render() {
      const { fetching, error, channel } = this.props
      return (
        <WrappedComponent
          {...this.props}
          fetching={fetching}
          error={error}
          handleSubmit={this.handleSubmit}
          onCancel={this.onCancel}
        />
      )
    }
  }

  Update.propTypes = {
    t: PropTypes.func.isRequired,
    getChannelOptions: PropTypes.func.isRequired,
    getChannel: PropTypes.func.isRequired,
    getChannels: PropTypes.func.isRequired,
    updateChannel: PropTypes.func.isRequired,
    channel: PropTypes.object,
    match: PropTypes.object,
    fetching: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.object.isRequired,
  }

  Update.defaultProps = {
    channel: {},
    match: {},
    fetching: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    options: api.channels_options || {},
    channel: api.channel || {},
    loading: api.requests.channel.put.loading,
    fetching: api.requests.channel.put.fetching,
    fetching_channel: api.requests.channel.get.fetching,
    error: api.requests.channel.put.error,
  })

  const mapDispatchToProps = dispatch => ({
    updateChannel: (data, id, props = {}) =>
      dispatch({
        type: 'UPDATE_CHANNEL',
        data: { channel: data },
        vars: { id },
        ...props,
      }),
    getChannelOptions: props =>
      dispatch({
        type: 'GET_CHANNELS_OPTIONS',
        ...props,
      }),
    getChannel: (channel_id, props) =>
      dispatch({
        type: 'GET_CHANNEL',
        vars: { channel_id },
        ...props,
      }),
    getChannels: props =>
      dispatch({
        type: 'GET_CHANNELS',
        ...props,
      }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Update))
}

export default HOC
