import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader } from 'semantic-ui-react'
import Modal from '../../../atoms/Modal'

const Show = ({ user, onClose, error, fetching, t }) => (
  <Modal
    size="tiny"
    open={true}
    close={onClose}
    headerIcon="user"
    headerContent="Admin Details"
  >
    {fetching && (
      <Dimmer inverted active={true}>
        <Loader size="medium">Fetching user data...</Loader>
      </Dimmer>
    )}
    <table className="admin-details-table">
      <tbody>
        <tr>
          <th className="admin-details-table_th">{t('Full Name')}</th>
          <td>{user.full_name}</td>
        </tr>
        <tr>
          <th className="admin-details-table_th">{t('User Name')}</th>
          <td>{user.username}</td>
        </tr>
        <tr>
          <th className="admin-details-table_th">{t('Email')}</th>
          <td>{user.email}</td>
        </tr>
        <tr>
          <th className="admin-details-table_th">{t('Mobile')}</th>
          <td>{user.mobile}</td>
        </tr>
      </tbody>
    </table>
  </Modal>
)

export default Show

Show.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.object,
  fetching: PropTypes.bool,
  error: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

Show.defaultProps = {
  user: {},
  fetching: false,
  error: null,
}
