import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'semantic-ui-react'
import LoginBox from 'organisms/LoginBox'
import logo from '../../../asset/images/logo.png'

const Login = ({ fields, ButtonProps, label, onSubmit, error }) => (
  <div className="tu-form-box">
    <Image
      src={logo}
      size="small"
      style={{ marginBottom: '20px', height: '70px', width: 'auto' }}
    />
    <LoginBox
      fields={fields}
      label={label}
      onSubmit={onSubmit}
      ButtonProps={ButtonProps}
    />
  </div>
)

Login.propTypes = {
  fields: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  ButtonProps: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.func,
}

Login.defaultProps = {
  error: false,
}

export default Login
