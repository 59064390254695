import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  class Update extends React.Component {
    componentWillMount() {
      const { getIntroVideoOptions, getIntroVideo, match } = this.props
      //  getIntroVideoOptions()
      console.log('match.params.id', match.params.id)
      getIntroVideo(match.params.id)
    }

    onSuccess = res => {
      const { history, getIntroVideos } = this.props
      Toaster.success(res.data.message)
      getIntroVideos()
      history.goBack()
    }

    handleSubmit = values => {
      const { updateIntroVideo, match } = this.props
      updateIntroVideo(values, match.params.id, { onFulfilled: this.onSuccess })
    }

    onCancel = () => {
      const { history } = this.props
      history.goBack()
    }

    render() {
      const { fetching, error } = this.props
      return (
        <WrappedComponent
          {...this.props}
          fetching={fetching}
          error={error}
          handleSubmit={this.handleSubmit}
          onCancel={this.onCancel}
        />
      )
    }
  }

  Update.propTypes = {
    t: PropTypes.func.isRequired,
    getIntroVideoOptions: PropTypes.func.isRequired,
    getIntroVideo: PropTypes.func.isRequired,
    getIntroVideos: PropTypes.func.isRequired,
    updateIntroVideo: PropTypes.func.isRequired,
    intro_video: PropTypes.object,
    match: PropTypes.object,
    fetching: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.object.isRequired,
  }

  Update.defaultProps = {
    intro_video: {},
    match: {},
    fetching: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    options: api.intro_videos_options || {},
    intro_video: api.intro_video || {},
    loading: api.requests.intro_video.put.loading,
    fetching: api.requests.intro_video.put.fetching,
    fetching_intro_video: api.requests.intro_video.get.fetching,
    error: api.requests.intro_video.put.error,
  })

  const mapDispatchToProps = dispatch => ({
    updateIntroVideo: (data, id, props = {}) =>
      dispatch({
        type: 'UPDATE_INTRO_VIDEO',
        data: { introvideo: data },
        vars: { id },
        ...props,
      }),
    getIntroVideoOptions: props =>
      dispatch({
        type: 'GET_INTRO_VIDEOS_OPTIONS',
        ...props,
      }),
    getIntroVideo: (intro_video_id, props) =>
      dispatch({
        type: 'GET_INTRO_VIDEO',
        vars: { id: intro_video_id },
        ...props,
      }),
    getIntroVideos: props =>
      dispatch({
        type: 'GET_INTRO_VIDEOS',
        ...props,
      }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Update))
}

export default HOC
