import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const HOCLogin = WrappedComponent => {
  class Login extends Component {
    handleOnSubmit = values => {
      const { userLogin } = this.props
      userLogin(values, { onFulfilled: this.onSuccessLogin })
    }

    onSuccessLogin = response => {
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('user', JSON.stringify(response.data.user))
      const { history } = this.props
      this.props.history.go('/')
    }

    render() {
      return (
        <WrappedComponent
          validation={this.validation}
          onSubmit={this.handleOnSubmit}
          {...this.props}
        />
      )
    }
  }

  const mapStateToProps = ({ api }) => ({
    auth: api.auth,
    error: api.requests.auth.post.error,
    fetching: api.requests.auth.post.fetching,
  })

  const mapDispatchToProps = dispatch => ({
    userLogin: (user, props) =>
      dispatch({
        type: 'USER_LOGIN',
        data: { user },
        ...props,
      }),
  })

  Login.propTypes = {
    dispatch: PropTypes.func,
    userLogin: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  }

  Login.defaultProps = {
    dispatch: () => {},
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Login)
}

export default HOCLogin
