import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'

const AppContainer = ({ header, button, children }) => (
  <div className="app-container">
    <Grid>
      <Grid.Row>
        <Grid.Column className="container-header--left" width={10} mobile={16}>
          <h1>{header}</h1>
        </Grid.Column>
        <Grid.Column className="container-header--right" width={6} mobile={16}>
          {button}
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <div className="container-body">{children}</div>
  </div>
)

export default AppContainer

AppContainer.propTypes = {
  header: PropTypes.string,
  button: PropTypes.node,
  children: PropTypes.any,
}

AppContainer.defaultProps = {
  header: '',
  button: null,
  children: null,
}
