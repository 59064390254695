import React, { Component } from 'react'
import { connect } from 'react-redux'
import swal from 'sweetalert'

const warrningMessages = [
  {
    title: 'Deactive Channel',
    text: 'are you sure you want to Deactive the channel ?',
    btn: 'Yes, Deactive it!',
    successTitle: 'Deactivated',
  },
  {
    title: 'Active Channel',
    text: 'are you sure you want to Active the channel ?',
    btn: 'Yes, Active it!',
    successTitle: 'Activated!',
  },
]
warrningMessages[2] = warrningMessages[1]
warrningMessages[1] = warrningMessages[0]

const HOC = WrappedComponent => {
  class Channels extends Component {
    channel_id = this.props.match.params.channel_id

    state = {
      filters: {
        status: null,
        search: null,
        page: 1,
        limit: 20,
        direction: 'desc',
        sort: 'last_video_published_at',
        age_group_id: null,
      },
    }

    handleFilters = (key, value) => {
      const { filters } = this.state
      filters[key] = value
      filters.page = key === 'page' ? value : 1
      this.setState({ filters })
      this.loadChannels(filters)
    }

    handleDelete = channel_id => {
      const { deleteChannel } = this.props

      swal({
        title: 'Are you sure?',
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          catch: {
            text: 'Yes, Delete',
            closeModal: false,
          },
        },
      })
        .then(confirm => {
          if (!confirm) throw null
          return new Promise((resolve, reject) => {
            const onFulfilled = respo => {
              resolve(respo.data.message)
              this.loadChannels()
            }

            const onFaild = res => {
              reject(res)
            }
            deleteChannel(channel_id, { onFulfilled, onFaild })
          })
        })
        .then(res => {
          swal({
            title: 'Deleted!',
            text: res,
            icon: 'success',
            button: false,
            timer: 2000,
          })
        })
        .catch(err => {
          if (err) {
            swal({
              title: 'Error',
              text: err,
              icon: 'error',
              button: false,
            })
          } else {
            swal.stopLoading()
            swal.close()
          }
        })
    }

    handleStatus = (channel_id, status) => {
      const { updateChannel } = this.props
      const msg = warrningMessages[status]

      swal({
        title: msg.title,
        text: msg.text,
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          catch: {
            text: msg.btn,
            closeModal: false,
          },
        },
      })
        .then(confirm => {
          if (!confirm) throw null
          return new Promise((resolve, reject) => {
            const onFulfilled = respo => {
              resolve(respo.data.message)
              this.loadChannels()
            }

            const onFaild = res => {
              reject(res)
            }
            updateChannel(channel_id, { status }, { onFulfilled, onFaild })
          })
        })
        .then(res => {
          swal({
            title: msg.successTitle,
            text: res,
            icon: 'success',
            button: false,
            timer: 2000,
          })
        })
        .catch(err => {
          if (err) {
            swal({
              title: 'Error',
              text: err,
              icon: 'error',
              button: false,
            })
          } else {
            swal.stopLoading()
            swal.close()
          }
        })
    }

    loadChannels = (filters = this.state.filters) => {
      const { getChannels } = this.props
      getChannels({ params: filters })
    }

    componentDidMount() {
      this.loadChannels()
      this.props.getOptions()
      // this.props.getVideoOptions()
    }

    render() {
      const channels = this.props.channels.channels || []
      return (
        <WrappedComponent
          {...this.props}
          channels={channels}
          filters={this.state.filters}
          pagination={this.props.channels.pagination || {}}
          noChannels={!this.props.fetching && channels.length <= 0}
          handleFilters={this.handleFilters}
          handleDelete={this.handleDelete}
          handleStatus={this.handleStatus}
        />
      )
    }
  }

  const mapStateToProps = ({ api }) => ({
    channels: api.channels || {},
    fetching: api.requests.channels.get.fetching,
    error: api.requests.channels.get.error,
    options: api.options || {},
  })

  const mapDispatchToProps = dispatch => ({
    getChannels: props =>
      dispatch({
        type: 'GET_CHANNELS',
        ...props,
      }),
    getOptions: props =>
      dispatch({
        type: 'GET_OPTIONS',
      }),
    deleteChannel: (id, props) =>
      dispatch({
        type: 'DELETE_CHANNEL',
        vars: { id },
        ...props,
      }),
    updateChannel: (id, data, props) =>
      dispatch({
        type: 'UPDATE_CHANNEL',
        vars: { id },
        data: { channel: data },
        ...props,
      }),
  })
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Channels)
}

export default HOC
