import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Button, Grid, Message } from 'semantic-ui-react'
import reduxForm from 'atoms/reduxForm'
import dropdownInput from 'molecules/reduxFormFields/dropdownInput'

const Form = ({
  handleSubmit,
  validate,
  t,
  fetching,
  errorMessage,
  options,
}) => {
  const categories =
    options.categories &&
    options.categories.map(cat => ({ text: cat.title, value: cat.id }))
  const age_groups =
    options.age_groups &&
    options.age_groups.map(age => ({ text: age.title, value: age.id }))
  return (
    <Grid columns={1}>
      {errorMessage && (
        <Grid.Row className="admin-form_error-message">
          <Message negative className="admin-form_error-message">
            {errorMessage}
          </Message>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column className="admin-form_field">
          <Field
            validate={[validate.required]}
            name="category_id"
            title={t('Channel Category')}
            options={categories}
            component={dropdownInput}
            search
            clearable
          />
        </Grid.Column>
        <Grid.Column className="admin-form_field">
          <Field
            validate={[validate.required]}
            name="age_group_ids"
            title={t('Audiance & Age Group')}
            options={age_groups}
            multiple
            component={dropdownInput}
            search
          />
        </Grid.Column>

        <Grid.Column className="admin-form_button">
          <Button primary disabled={fetching} onClick={handleSubmit}>
            {t('Add To Tuby')}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

Form.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  validate: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  isCreate: PropTypes.bool,
}
Form.defaultProps = {
  fetching: false,
  errorMessage: null,
  isCreate: false,
}
export default reduxForm({
  form: 'users_form',
  enableReinitialize: true,
})(Form)
