import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Button, Grid, Message } from 'semantic-ui-react'
import reduxForm from 'atoms/reduxForm'
import textInput from 'molecules/reduxFormFields/textInput'

const Form = ({
  handleSubmit,
  validate,
  t,
  fetching,
  errorMessage,
  isCreate,
}) => (
  <Grid columns={1}>
    {errorMessage && (
      <Grid.Row className="admin-form_error-message">
        <Message negative className="admin-form_error-message">
          {errorMessage}
        </Message>
      </Grid.Row>
    )}
    <Grid.Row>
      <Grid.Column className="admin-form_field">
        <Field
          validate={[validate.required, validate.IPAddress]}
          name="start_ip"
          title={t('Start IP')}
          component={textInput}
        />
      </Grid.Column>
      <Grid.Column className="admin-form_field">
        <Field
          validate={[validate.required, validate.IPAddress]}
          name="end_ip"
          title={t('End IP')}
          component={textInput}
        />
      </Grid.Column>
      <Grid.Column className="admin-form_button">
        <Button primary disabled={fetching} onClick={handleSubmit}>
          {t(isCreate ? 'Save' : 'Update')}
        </Button>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

Form.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  validate: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  isCreate: PropTypes.bool,
}
Form.defaultProps = {
  fetching: false,
  errorMessage: null,
  isCreate: false,
}
export default reduxForm({
  form: 'users_form',
  enableReinitialize: true,
})(Form)
