import React, { Component } from 'react'
import VideoModal from './VideoModal'

const HOC = WrappedComponent => {
  class VideoCard extends Component {
    state = { activeModal: false }
    handleCloseModal = () => {
      this.setState({ activeModal: !this.state.activeModal })
    }
    render() {
      const { activeModal } = this.state
      return (
        <React.Fragment>
          <WrappedComponent
            {...this.props}
            handleModal={this.handleCloseModal}
          />
          {activeModal && (
            <VideoModal
              active={activeModal}
              handleClose={this.handleCloseModal}
              video_id={this.props.video_id}
            />
          )}
        </React.Fragment>
      )
    }
  }
  return VideoCard
}

export default HOC
