import React from 'react'
import { Grid } from 'semantic-ui-react'
import { DatePickerInput } from 'rc-datepicker'
import 'rc-datepicker/lib/style.css'
import moment from 'moment'

const dateFormat = 'DD-MM-YYYY HH:mm:ss'
const Filters = ({ handleFilters, filters }) => (
  <React.Fragment>
    <Grid>
      <Grid.Row>
        <Grid.Column computer={8} mobile={16} centered verticalAlign="middle">
          <span style={{ marginRight: 10 }}>Filter : From </span>
          <DatePickerInput
            readOnly
            showOnInputClick
            returnFormat={dateFormat}
            onChange={(jsDate, dateString) =>
              handleFilters(['start_date', 'end_date'], [dateString, null])
            }
            onClear={() => {
              handleFilters(['start_date', 'end_date'], [null, null])
            }}
          />
        </Grid.Column>
        <Grid.Column computer={8} mobile={16} centered verticalAlign="middle">
          <span style={{ marginLeft: 10, marginRight: 10 }}> To </span>
          <DatePickerInput
            readOnly
            showOnInputClick
            value={filters.end_date}
            returnFormat={dateFormat}
            onChange={(jsDate, dateString) =>
              handleFilters('end_date', dateString)
            }
            disabled={!filters.start_date}
            minDate={moment(filters.start_date, dateFormat).toDate()}
            onClear={
              filters.end_date !== null
                ? () => handleFilters('end_date', null)
                : () => {}
            }
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </React.Fragment>
)

export default Filters
