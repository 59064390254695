import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'semantic-ui-react'

const VideoModal = ({ active, handleClose, video_id }) => (
  <Modal dimmer="blurring" open={active} onClose={handleClose}>
    <Modal.Content className="video-modal" style={{ padding: 0 }}>
      <iframe
        width="100%"
        height="500"
        src={`https://www.youtube.com/embed/${video_id}`}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; picture-in-picture"
        allowfullscreen
      />
    </Modal.Content>
  </Modal>
)

VideoModal.defaultProps = {
  video: {},
}
export default VideoModal
