import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { translate } from 'react-i18next'
import { Image, Dropdown, Grid, Icon } from 'semantic-ui-react'
import logOut from '../../../functions/auth'
import logo from '../../../asset/images/logo.png'

const user = JSON.parse(localStorage.getItem('user'))

const trigger = (
  <span>
    <span
      style={{ display: 'inline-block', padding: '0 10px', color: '#7b7979' }}
    >
      {user && user.full_name}
    </span>
    <Image
      avatar
      src="https://4-seasonstorage.com/wp-content/uploads/2016/12/agent-e202505f.png"
    />
  </span>
)

const Header = ({ t, i18n, toggleSidebar }) => (
  <header className="tu-header">
    <div style={{ display: 'inline-block', width: '50%' }}>
      <Grid>
        <Grid.Column width={2} centered verticalAlign="middle" only="mobile">
          <Icon
            className="tu-header__sidebar-togglor"
            size="large"
            name="sidebar"
            onClick={toggleSidebar}
          />
        </Grid.Column>

        <Grid.Column width={10}>
          <Image
            src={logo}
            size="small"
            style={{ display: 'flex', height: 40, width: 40 }}
          />
        </Grid.Column>
      </Grid>
    </div>

    {user && (
      <div style={{ float: 'right', padding: '5px 0' }}>
        <Dropdown
          direction="left"
          trigger={trigger}
          pointing="top left"
          icon={null}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              icon="user"
              text="Profile"
              as={Link}
              to={`/users/${user.id}/details`}
            />
            <Dropdown.Item
              icon="sign out"
              text="Sign out"
              onClick={() => logOut()}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )}
  </header>
)

Header.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
}

export default translate('translations')(Header)
