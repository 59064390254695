import React from 'react'
import { Grid } from 'semantic-ui-react'
import Search from 'molecules/Search'
import Dropdown from 'molecules/semantic-ui/Dropdown'

const Filters = ({
  disabled,
  handleFilters,
  options,
  channel_options,
  channels,
  channelsSearch,
  fetch_channels,
}) => (
  <React.Fragment>
    <Grid.Column computer={4} mobile={16}>
      <Search
        disabled={disabled}
        fluid
        size="small"
        icon="search"
        iconPosition="left"
        placeholder="Search..."
        onTyping={value => handleFilters('search', value)}
      />
    </Grid.Column>
    <Grid.Column computer={5} mobile={16} centered verticalAlign="middle">
      <div
        style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}
      >
        <div style={{ marginRight: 10 }}>Filter By</div>
        <Dropdown
          selection
          clearable
          fluid
          disabled={disabled}
          placeholder="Status"
          labelKey="title"
          valueKey="id"
          options={options.statuses}
          onChange={(e, data) => handleFilters('status', data.value)}
        />
      </div>
    </Grid.Column>
    <Grid.Column computer={4} mobile={16} centered verticalAlign="middle">
      <Dropdown
        selection
        search
        clearable
        fluid
        placeholder="Channel Name"
        labelKey="title"
        valueKey="id"
        loading={fetch_channels}
        options={channels}
        onChange={(e, data) => handleFilters('channel_id', data.value)}
        onSearchChange={(event, { searchQuery }) => channelsSearch(searchQuery)}
      />
    </Grid.Column>
    <Grid.Column computer={3} mobile={16} centered verticalAlign="middle">
      <Dropdown
        selection
        clearable
        fluid
        placeholder="Video Type"
        labelKey="title"
        valueKey="id"
        onChange={(e, data) => handleFilters('video_type', data.value)}
        options={options.video_types}
      />
    </Grid.Column>

    {/*
    <Grid.Column computer={3} mobile={16} centered verticalAlign="middle">
      <Dropdown
        selection
        clearable
        fluid
        placeholder="Recommendation"
        labelKey="title"
        valueKey="id"
        onChange={(e, data) => handleFilters('recommended', data.value)}
        options={[
                  { id: 0, title: 'Not Recommended' },
                  { id: 1, title: 'Recommended' },
                ]}
      />
    </Grid.Column>

  <Grid.Column width={3} centered verticalAlign="middle"  computer={3} mobile={16}>
    <Dropdown
      selection
      clearable
      disabled={disabled}
      placeholder="Category"
      labelKey="title"
      valueKey="id"
      options={channel_options.categories}
      onChange={(e, data) => handleFilters('category_id', data.value)}
    />
  </Grid.Column>
*/}
  </React.Fragment>
)

export default Filters
