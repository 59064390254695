import React from 'react'
import PropTypes from 'prop-types'
import { Button, Pagination } from 'semantic-ui-react'
import { Link, Route } from 'react-router-dom'
import Table from '../../../atoms/Table'
import AppContainer from '../../../templates/AppContainer'
import Create from '../Create'
import Update from '../Update'
import Show from '../Show'

const Users = ({
  headers,
  data,
  pagination,
  activePage,
  handlePaginationChange,
  loading,
  error,
  t,
}) => (
  <AppContainer
    header={t('Users')}
    button={
      <Button
        icon="plus"
        color="violet"
        content="New User"
        primary
        as={Link}
        to="users/create"
      />
    }
  >
    <Table headers={headers} data={data} loading={loading} error={error} />
    <Route exact component={Create} path="/users/create" />
    <Route exact component={Show} path="/users/:id/details" />
    <Route exact component={Update} path="/users/:id/edit" />
    {pagination.total_pages > 1 && (
      <Pagination
        activePage={activePage}
        onPageChange={handlePaginationChange}
        size="mini"
        totalPages={pagination.total_pages}
        ellipsisItem={null}
      />
    )}
  </AppContainer>
)

Users.propTypes = {
  t: PropTypes.func.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string,
      render: PropTypes.func,
    }),
  ).isRequired,
  data: PropTypes.array,
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  activePage: PropTypes.number,
  handlePaginationChange: PropTypes.func.isRequired,
}

Users.defaultProps = {
  data: [],
  pagination: {},
  loading: false,
  error: null,
  activePage: null,
}

export default Users
