import React from 'react'
import PropTypes from 'prop-types'

const HOC = WrappedComponent => {
  class VideoPlayer extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  VideoPlayer.propTypes = {
    match: PropTypes.object,
  }

  VideoPlayer.defaultProps = {
    match: {},
  }
  return VideoPlayer
}
export default HOC
