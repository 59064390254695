import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Button, Dimmer, Loader } from 'semantic-ui-react'
import AppContainer from 'templates/AppContainer'
import TotalCards from './TotalCards'
import TopChannels from './TopChannels/index'
import TopVideos from './TopVideos/index'
import ViewsByCategory from './ViewsByCategory/index'
import VideoModal from '../../../templates/VideoCard/VideoModal'

const Container = ({
  dashboard: {
    total_channels,
    total_videos,
    total_exclusives,
    total_features,
    top_ten_channels,
    top_ten_videos,
    categories,
    total_custom_channels,
  },
  fetching,
  loading,
  handleFilters,
  filters,
  activeModal,
  videoId,
  handleCloseModal,
}) => (
  <AppContainer
    header="Dashboard"
    /* button={(
      <Button
        icon="plus"
        color="violet"
        content="Export"
        primary
        as={Link}
        to="/dashboard"
      />
    )}
    */
  >
    <React.Fragment>
      <div style={{ position: 'relative' }}>
        {fetching && (
          <Dimmer inverted active>
            <Loader size="medium" />
          </Dimmer>
        )}

        <TotalCards
          total_channels={total_channels}
          total_videos={total_videos}
          total_exclusives={total_exclusives}
          total_features={total_features}
          total_channels_added_by_parents={total_custom_channels}
        />

        <Grid columns={2}>
          <TopChannels top_ten_channels={top_ten_channels} />
          <TopVideos
            top_ten_videos={top_ten_videos}
            handleCloseModal={handleCloseModal}
          />
        </Grid>

        <Grid columns={2}>
          <ViewsByCategory categories={categories} />
        </Grid>
      </div>
      {activeModal && (
        <VideoModal
          active={activeModal}
          handleClose={handleCloseModal}
          video_id={videoId}
        />
      )}
    </React.Fragment>
  </AppContainer>
)

export default Container
