import React from 'react'
import PropTypes from 'prop-types'
import { Input as SemInput } from 'semantic-ui-react'

const Input = ({ title, meta: { error, touched }, ...props }) => (
  <div>
    <div className="tuby-input__label">{title}</div>
    <SemInput fluid {...props} error={!!(touched && error)} />
    {error && touched && <div className="tuby-input--error">{error}</div>}
  </div>
)

Input.propTypes = {
  title: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
}

export default Input
