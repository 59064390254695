export function validations(values) {
  const errors = {}

  if (values.email && !/^.+@.+$/i.test(values.email))
    errors.email = 'Enter valid email!'

  // if (values.password && values.password.length < 7)
  //   errors.password = 'Enter atleast 8 characters'

  return errors
}
