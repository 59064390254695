import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  class Create extends React.Component {
    onSuccess = res => {
      const { history } = this.props
      Toaster.success(res.data.message)
      history.push('/pages')
    }

    handleSubmit = values => {
      const { createPage } = this.props
      createPage(values, { onFulfilled: this.onSuccess })
    }

    onCancel = () => {
      const { history } = this.props
      history.goBack()
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleSubmit={this.handleSubmit}
          onCancel={this.onCancel}
        />
      )
    }
  }

  Create.propTypes = {
    createPage: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  }

  const mapStateToProp = ({ api }) => ({
    loading: api.requests.page.post.loading,
    fetching: api.requests.page.post.fetching,
    error: api.requests.page.post.error,
  })

  const mapDispatchToProps = dispatch => ({
    createPage: (data, props = {}) =>
      dispatch({
        type: 'CREATE_PAGE',
        data: { about_app: data },
        ...props,
      }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Create))
}

export default HOC
