import React from 'react'
import PropTypes from 'prop-types'
import { Image, Card, Divider } from 'semantic-ui-react'
import Status from 'atoms/Status'
import './style.css'
// import { BlockPicker } from 'react-color'
import ListItem from './ListItem'
import Color from './Color'
import ColorBlock from './ColorBlock'

const defaultImage = 'https://react.semantic-ui.com/images/wireframe/image.png'

const ThemeInfo = ({ theme }) => (
  <div className="booking-container">
    <div className="theme-row">
      <div className="theme-column">
        <ListItem
          header="English Title"
          description={theme.title_en ? theme.title_en : 'Not Found'}
        />
        <div className="theme-column">
          <div className="theme-item">
            <strong style={{ marginBottom: 10 }}>Main Color</strong>
            {/*   <BlockPicker color={theme.main_color} /> */}
            {/* <ColorBlock background={theme.main_color || 'white'} /> */}
            {theme.main_color ? (
              <Color
                color={
                  `rgb(${theme.main_color &&
                    theme.main_color.r},${theme.main_color &&
                    theme.main_color.g},${theme.main_color &&
                    theme.main_color.b},${theme.main_color &&
                    theme.main_color.a})` || 'white'
                }
                name={
                  `rgb(${theme.main_color &&
                    theme.main_color.r},${theme.main_color &&
                    theme.main_color.g},${theme.main_color &&
                    theme.main_color.b},${theme.main_color &&
                    theme.main_color.a})` || 'Not Found'
                }
              />
            ) : (
              'Not Found'
            )}
          </div>
        </div>
      </div>
      <div className="theme-column">
        <ListItem
          header="Arabic Title"
          description={theme.title_ar ? theme.title_ar : 'Not Found'}
        />
        <div className="theme-column">
          <div className="theme-item">
            <strong style={{ marginBottom: 10 }}>Second Color</strong>
            {/* <BlockPicker color={theme.second_color} /> */}
            {/*    <ColorBlock background={theme.second_color || 'white'} /> */}
            {theme.second_color ? (
              <Color
                color={
                  `rgb(${theme.second_color &&
                    theme.second_color.r},${theme.second_color &&
                    theme.second_color.g},${theme.second_color &&
                    theme.second_color.b},${theme.second_color &&
                    theme.second_color.a})` || 'white'
                }
                name={
                  `rgb(${theme.second_color &&
                    theme.second_color.r},${theme.second_color &&
                    theme.second_color.g},${theme.second_color &&
                    theme.second_color.b},${theme.second_color &&
                    theme.second_color.a})` || 'Not Found'
                }
              />
            ) : (
              'Not Found'
            )}
          </div>
        </div>
      </div>

      <div className="theme-column">
        <ListItem
          header="Status"
          description={
            theme.status_label ? (
              <Status
                name={theme.status_label && theme.status_label.toLowerCase()}
              />
            ) : (
              'Not Found'
            )
          }
        />
      </div>
    </div>

    {/* <Color
      color={{ background: theme.main_color } || 'black'}
      name={theme.main_color || 'Not Found'}
    /> */}

    <Divider />

    <div className="theme-row">
      <div className="theme-column">
        <Card>
          <Image src={theme.recommended_url || defaultImage} />
          <Card.Content>
            <Card.Header>Recommended Image</Card.Header>
          </Card.Content>
        </Card>

        <Card>
          <Image src={theme.recommended_tab_url || defaultImage} />
          <Card.Content>
            <Card.Header>Recommended Tablet Image</Card.Header>
          </Card.Content>
        </Card>
        <Card>
          <Image src={theme.default_url || defaultImage} />
          <Card.Content>
            <Card.Header>Default Image</Card.Header>
          </Card.Content>
        </Card>
      </div>

      <div className="theme-column">
        <Card>
          <Image src={theme.channel_url || defaultImage} />
          <Card.Content>
            <Card.Header>Channel Image</Card.Header>
          </Card.Content>
        </Card>

        <Card>
          <Image src={theme.channel_tab_url || defaultImage} />
          <Card.Content>
            <Card.Header>Channel Tablet Image</Card.Header>
          </Card.Content>
        </Card>
        <Card>
          <Image src={theme.default_tab_url || defaultImage} />
          <Card.Content>
            <Card.Header>Default Tablet Image</Card.Header>
          </Card.Content>
        </Card>
      </div>

      <div className="theme-column">
        <Card>
          <Image src={theme.video_url || defaultImage} />
          <Card.Content>
            <Card.Header>Video Image</Card.Header>
          </Card.Content>
        </Card>

        <Card>
          <Image src={theme.video_tab_url || defaultImage} />
          <Card.Content>
            <Card.Header>Video Tablet Image</Card.Header>
          </Card.Content>
        </Card>
      </div>

      <div className="theme-column">
        <Card>
          <Image src={theme.history_url || defaultImage} />
          <Card.Content>
            <Card.Header>History Image</Card.Header>
          </Card.Content>
        </Card>
        <Card>
          <Image src={theme.history_tab_url || defaultImage} />
          <Card.Content>
            <Card.Header>History Tablet Image</Card.Header>
          </Card.Content>
        </Card>
      </div>
    </div>
  </div>
)
export default ThemeInfo

/*

  <div>
            <div style={styles} />
          </div>
  let styles = {
      margin: '20px',
      width: '250px',
      height: '250px',
      backgroundColor: 'yellow',
    };
*/
