import React from 'react'
import {
  Image,
  Grid,
  List,
  Header,
  Segment,
  Dimmer,
  Loader,
} from 'semantic-ui-react'
import Dropdown from 'molecules/semantic-ui/Dropdown'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import numberFixed from '../../../../../functions/numberFixed'

const TopChannels = ({
  topChannels,
  noTopChannels,
  fetching,
  loading,
  error,
  ...props
}) => (
  <Grid.Column mobile={16} tablet={16} computer={8}>
    <Segment raised>
      {/*
      <Dimmer inverted active>
        <Loader size="medium" />
      </Dimmer>
     */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Header as="h4"> Top 10 Channels</Header>
        </div>
        {/*
            <div>
              <Dropdown
                selection
                fluid
                placeholder="Sort"
                value="desc"
                labelKey="title"
                valueKey="key"
                options={[
                  { key: 'desc', title: 'LastWeek' },
                  { key: 'asc', title: 'AllTime' },
                ]}
                onChange={(e, data) => console.log('d', data)}
              />
            </div>
          */}
      </div>
      <List divided verticalAlign="middle">
        {topChannels.map((item, index) => (
          <List.Item
            key={item.id}
            onClick={() => props.history.push(`channels/${item.id}/show`)}
            style={{ cursor: 'pointer' }}
          >
            <List.Content floated="right">
              <List.Header>{numberFixed(item.views)}</List.Header>
              <List.Description>views</List.Description>
            </List.Content>
            <Image style={{ width: 40, height: 40 }} src={item.cover} />

            <List.Content>
              <List.Header>
                {`${`${item.name_channel}| ${item.category_name}`}`}
              </List.Header>
              <List.Description>
                {numberFixed(item.video_count)}
                {'  Videos '}
              </List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </Segment>
  </Grid.Column>
)

export default withRouter(TopChannels)
