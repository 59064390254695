import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Message } from 'semantic-ui-react'

const DeleteBtn = ({
  handleSubmit,
  t,
  fetching,
  errorMessage,
  ip_whitelist,
}) => (
  <Grid columns={1}>
    {errorMessage && (
      <Grid.Row className="admin-form_error-message">
        <Message negative className="admin-form_error-message">
          {errorMessage}
        </Message>
      </Grid.Row>
    )}
    <Grid.Row>
      <table className="admin-details-table">
        <tbody>
          <tr>
            <th className="admin-details-table_th" colSpan={2}>
              {t('Are you sure you want to delete this IP ?')}
            </th>
          </tr>
          <tr>
            <th className="admin-details-table_th">{t('Start IP')}</th>
            <td>{ip_whitelist.start_ip}</td>
          </tr>
          <tr>
            <th className="admin-details-table_th">{t('End IP')}</th>
            <td>{ip_whitelist.end_ip}</td>
          </tr>
        </tbody>
      </table>
      <Grid.Column className="admin-form_button">
        <Button primary disabled={fetching} onClick={handleSubmit}>
          {t('Delete')}
        </Button>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

DeleteBtn.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  ip_whitelist: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  errorMessage: PropTypes.string,
}
DeleteBtn.defaultProps = {
  fetching: false,
  errorMessage: null,
}
export default DeleteBtn
