import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Button, Grid, Message, Dimmer, Loader } from 'semantic-ui-react'
import reduxForm from 'atoms/reduxForm'
import textInput from 'molecules/reduxFormFields/textInput'
import dropdownInput from 'molecules/reduxFormFields/dropdownInput'
import VideoFileInput from 'molecules/reduxFormFields/VideoFileInput'

const statuses = [{ text: 'Active', value: 2 }, { text: 'InActive', value: 1 }]
const Form = ({
  handleSubmit,
  onCancel,
  validate,
  t,
  fetching,
  errorMessage,
  isCreate,
  ...props
}) => (
  <React.Fragment>
    {errorMessage && (
      <Message negative className="theme-form--error-message">
        {errorMessage}
      </Message>
    )}
    <Grid doubling columns={2}>
      {fetching && (
        <Dimmer inverted active>
          <Loader size="medium">Processing...</Loader>
        </Dimmer>
      )}
      {console.log('f props ', props)}
      <Grid.Row>
        <Grid.Column width={4} className="theme-form_field">
          <Field
            validate={[validate.required]}
            name="title_en"
            title={t('English Title')}
            component={textInput}
          />
        </Grid.Column>
        <Grid.Column width={8} className="theme-form_field">
          <Field
            validate={[validate.required]}
            name="title_ar"
            title={t('Arabic Title')}
            component={textInput}
          />
        </Grid.Column>

        <Grid.Column width={4} className="channel-form_field">
          <Field
            name="status"
            title={t('Status')}
            options={statuses}
            component={dropdownInput}
            clearable
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4} className="theme-form_field">
          <Field
            component={VideoFileInput}
            name="video"
            title={t('Intro Video')}
            validate={isCreate && [validate.required]}
            video={!isCreate ? props.initialValues.video_url : ''}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <Button primary disabled={fetching} onClick={handleSubmit}>
            {t('Save')}
          </Button>
          <Button color="grey" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </React.Fragment>
)

Form.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  validate: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  isCreate: PropTypes.bool,
  errorMessage: PropTypes.string,
  options: PropTypes.object,
}
Form.defaultProps = {
  fetching: false,
  isCreate: false,
  errorMessage: null,
  options: {},
}
export default reduxForm({
  form: 'themes_form',
  enableReinitialize: true,
})(Form)
