import React from 'react'
import PropTypes from 'prop-types'
import { Modal as SemModal, Header } from 'semantic-ui-react'

const Modal = ({ size, open, close, headerIcon, headerContent, children }) => (
  <SemModal size={size} open={open} onClose={close} closeIcon dimmer="blurring">
    <Header icon={headerIcon} content={headerContent} />
    <SemModal.Content>{children}</SemModal.Content>
  </SemModal>
)

Modal.propTypes = {
  size: PropTypes.string,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  headerIcon: PropTypes.string,
  headerContent: PropTypes.string.isRequired,
  children: PropTypes.any,
}

Modal.defaultProps = {
  size: 'small',
  headerIcon: null,
  children: null,
}

export default Modal
