import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Button, Form } from 'semantic-ui-react'
import Input from '../../molecules/semantic-ui/Input'

/**
 * @render react
 * @name LoginBox
 * @description Login Box
 * @example
 * <LoginBox onSubmit={values => console.log(values)}/>
 */

const LoginBox = ({
  handleSubmit,
  handleOnChange,
  errors,
  fields,
  label,
  ButtonProps,
}) => (
  <Form onSubmit={handleSubmit} size="small" style={{ width: 300 }}>
    {fields.map(field => (
      <Input
        key={`field-${field.name}`}
        fluid
        {...field}
        onChange={(e, { name, value }) => value && handleOnChange(name, value)}
        error={errors[field.name]}
      />
    ))}
    <Button fluid {...ButtonProps}>
      {label}
    </Button>
  </Form>
)

LoginBox.propTypes = {
  fields: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  label: PropTypes.string,
}

LoginBox.defaultProps = {
  label: 'Login',
  fields: [
    {
      name: 'email',
      placeholder: 'Email',
      type: 'email',
      icon: <Icon name="at" />,
      iconPosition: 'left',
      size: 'small',
    },
    {
      name: 'password',
      placeholder: 'Password',
      type: 'password',
      icon: <Icon name="lock" />,
      iconPosition: 'left',
      size: 'small',
    },
  ],
}
export default LoginBox
