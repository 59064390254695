import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Icon } from 'semantic-ui-react'

const Actions = ({ id, onEdit, onShow, onDelete }) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <Button
      as={Link}
      icon={<Icon name="edit" />}
      primary
      size="mini"
      to={`/ip-whitelists/${id}/edit`}
    />
    <Button
      as={Link}
      icon={<Icon name="eye" />}
      primary
      size="mini"
      to={`/ip-whitelists/${id}/show`}
    />
    <Button
      icon={<Icon name="delete" />}
      primary
      size="mini"
      onClick={onDelete}
    />
  </div>
)

Actions.propTypes = {
  id: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onShow: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Actions
