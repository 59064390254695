import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader } from 'semantic-ui-react'
import AppContainer from 'templates/AppContainer'
import Form from '../Create/Form'
import _ from 'lodash'

const Update = ({
  page,
  handleSubmit,
  error,
  fetching,
  fetching_page,
  onCancel,
  t,
}) => (
  <AppContainer header={t('Update Page')}>
    {fetching_page && (
      <Dimmer inverted active>
        <Loader size="medium">Fetching Page Data...</Loader>
      </Dimmer>
    )}
    <Form
      t={t}
      initialValues={_.pick(page, ['title_ar', 'title_en', 'content_ar', 'content_en'])}
      onSubmit={handleSubmit}
      errorMessage={error}
      fetching={fetching}
      fetching_page={fetching_page}
      onCancel={onCancel}
    />
  </AppContainer>
)

export default Update

Update.propTypes = {
  t: PropTypes.func.isRequired,
  page: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  fetching_page: PropTypes.bool,
  error: PropTypes.string,
}

Update.defaultProps = {
  page: {},
  fetching: false,
  fetching_page: false,
  error: null,
}
