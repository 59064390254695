import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import {
  Dimmer,
  Loader,
  Container,
  Header,
  Message,
  Divider,
} from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import HOC from './HOC'

const About = ({ data, fetching, match, error }) => (
  <Container
    text
    style={{
      margin: '50px 0',
      direction: match.params.locale === 'ar' && 'rtl',
    }}
    textAlign={match.params.locale === 'ar' && 'right'}
  >
    <Helmet>
      <meta charSet="utf-8" />
      <title>{data.title}</title>
    </Helmet>
    {fetching && (
      <Dimmer inverted active>
        <Loader size="medium">LOADING...</Loader>
      </Dimmer>
    )}
    {error && <Message warning header={error} />}
    <Header as="h2">{data.title}</Header>
    <Divider />
    <p>
      <ReactMarkdown source={data.content} escapeHtml={false} />
    </p>
  </Container>
)

About.propTypes = {
  data: PropTypes.object,
  match: PropTypes.object,
  fetching: PropTypes.bool,
  error: PropTypes.bool,
}

About.defaultProps = {
  data: {},
  match: {},
  fetching: false,
  error: null,
}

export default HOC(About)
