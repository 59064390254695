import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Dimmer, Loader, Table, Button } from 'semantic-ui-react'
import AppContainer from 'templates/AppContainer'
import ReactMarkdown from 'react-markdown'
import { getPageURL } from '../../../../functions/utilities'

const Update = ({ page, fetching, t }) => {
  const content_en = `<div>${page.content_en}</div>` || '<div></div>'
  const content_ar = `<div>${page.content_ar}</div>`
  const url = getPageURL(page.about_app_type_label)
  return (
    <AppContainer
      header={t('Page Details')}
      button={
        <Button
          icon="pencil alternate"
          basic
          color="violet"
          content="Edit"
          as={Link}
          to={`/pages/${page.id}/edit`}
        />
      }
    >
      {fetching && (
        <Dimmer inverted active>
          <Loader size="medium">Fetching Page Data...</Loader>
        </Dimmer>
      )}
      <Table className="admin-details-table">
        <Table.Body>
          <Table.Row>
            <Table.HeaderCell className="admin-details-table_th">
              {t('URL En')}
            </Table.HeaderCell>
            <Table.Cell>
              <a href={url} target="_blank">
                {url}
              </a>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className="admin-details-table_th">
              {t('URL Ar')}
            </Table.HeaderCell>
            <Table.Cell>
              <a href={`${url}/ar`} target="_blank">
                {url}/ar
              </a>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.HeaderCell className="admin-details-table_th">
              {t('Title En')}
            </Table.HeaderCell>
            <Table.Cell>{page.title_en}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className="admin-details-table_th">
              {t('Title Ar')}
            </Table.HeaderCell>
            <Table.Cell className="rtl">{page.title_ar}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className="admin-details-table_th">
              {t('Page Type')}
            </Table.HeaderCell>
            <Table.Cell colSpan={2}>{page.about_app_type_label}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className="admin-details-table_th">
              {t('Content En')}
            </Table.HeaderCell>
            <Table.Cell>
              <ReactMarkdown source={content_en} escapeHtml={false} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className="admin-details-table_th">
              {t('Content Ar')}
            </Table.HeaderCell>
            <Table.Cell>
              <ReactMarkdown
                source={content_ar}
                className="rtl"
                escapeHtml={false}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </AppContainer>
  )
}

export default Update

Update.propTypes = {
  t: PropTypes.func.isRequired,
  page: PropTypes.object,
  fetching: PropTypes.bool,
}

Update.defaultProps = {
  page: {},
  fetching: false,
}
