import React, { Component } from 'react'
import PropTypes from 'prop-types'

const HOC = WrappedComponent => {
  class Dropdown extends Component {
    handleOptionsStructure = () => {
      const { options, labelKey, valueKey } = this.props
      if (!options) return []
      const temp = []
      options.map(op => {
        temp.push({
          key: op[valueKey],
          value: op[valueKey],
          text: op[labelKey],
        })
      })
      return temp
    }

    render() {
      console.log('handleOptionsStructure', this.handleOptionsStructure())
      return (
        <WrappedComponent
          {...this.props}
          options={this.handleOptionsStructure()}
        />
      )
    }
  }

  Dropdown.defaultProps = {
    labelKey: 'text',
    valueKey: 'key',
  }
  return Dropdown
}

export default HOC
