import React, { Component } from 'react'
import { connect } from 'react-redux'

const HOC = WrappedComponent => {
  class TopChannels extends Component {
    state = {
      filters: {
        last_week: null,
        all_time: null,
      },
    }

    handleFilters = (key, value) => {
      const { filters } = this.state
      filters[key] = value
      this.setState({ filters })
      this.loadTopChannels(filters)
    }

    loadTopChannels = (filters = this.state.filters) => {
      const { getTopChannels } = this.props
      getTopChannels({ params: filters })
    }

    componentDidMount() {
      // this.loadTopChannels()
    }

    render() {
      // const topChannels = this.props.topChannels.topChannels || []
      const topChannels = this.props.top_ten_channels || []
      return (
        <WrappedComponent
          {...this.props}
          topChannels={topChannels}
          filters={this.state.filters}
          // noTopChannels={!this.props.fetching && topChannels.length <= 0}
          noTopChannels={topChannels.length <= 0}
          handleFilters={this.handleFilters}
        />
      )
    }
  }

  const mapStateToProps = ({ api }) => ({
    topChannels: api.topChannels || {},
    fetching: api.requests.topChannels, // .get.fetching,
    loading: api.requests.topChannels, // .get.loading,
    error: api.requests.topChannels, // .get.error,
  })

  const mapDispatchToProps = dispatch => ({
    getTopChannels: props =>
      dispatch({
        type: 'GET_TOP_CHANNELS',
        ...props,
      }),
  })
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TopChannels)
}

export default HOC
