import React, { Component } from 'react'
import { connect } from 'react-redux'

const HOC = WrappedComponent => {
  class TopVideos extends Component {
    state = {
      filters: {
        last_week: null,
        all_time: null,
      },
    }

    handleFilters = (key, value) => {
      const { filters } = this.state
      filters[key] = value
      this.setState({ filters })
      this.loadTopVideos(filters)
    }

    loadTopVideos = (filters = this.state.filters) => {
      const { getTopVideos } = this.props
      // getTopVideos({ params: filters })
    }

    componentDidMount() {
      //  this.loadTopVideos()
    }

    render() {
      // const topVideos = this.props.topVideos.topVideos || []
      const topVideos = this.props.top_ten_videos || []
      return (
        <WrappedComponent
          {...this.props}
          topVideos={topVideos}
          filters={this.state.filters}
          // noTopVideos={!this.props.fetching && topVideos.length <= 0}
          noTopVideos={topVideos.length <= 0}
          handleFilters={this.handleFilters}
        />
      )
    }
  }

  const mapStateToProps = ({ api }) => ({
    topVideos: api.topVideos || {},
    fetching: api.requests.topVideos, // .get.fetching,
    loading: api.requests.topVideos, // .get.loading,
    error: api.requests.topVideos, // .get.error,
  })

  const mapDispatchToProps = dispatch => ({
    getTopVideos: props =>
      dispatch({
        type: 'GET_TOP_VIDEOS',
        ...props,
      }),
  })
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TopVideos)
}

export default HOC
