import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader } from 'semantic-ui-react'
import Modal from '../../../atoms/Modal'
import Form from '../Create/Form'

const Update = ({
  user,
  onClose,
  handleSubmit,
  error,
  fetching,
  saving,
  t,
}) => (
  <Modal
    size="tiny"
    open={true}
    close={onClose}
    headerIcon="user"
    headerContent="Edit Admin"
  >
    {fetching && (
      <Dimmer inverted active={true}>
        <Loader size="medium">Fetching user data...</Loader>
      </Dimmer>
    )}
    <Form
      t={t}
      onSubmit={handleSubmit}
      errorMessage={error}
      fetching={saving}
      initialValues={user}
    />
  </Modal>
)

export default Update

Update.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  error: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

Update.defaultProps = {
  user: {},
  fetching: false,
  error: null,
}
