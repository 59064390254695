import React from 'react'
import PropTypes from 'prop-types'
import { Header, Icon } from 'semantic-ui-react'

const IntroVideoCard = ({
  channel,
  video,
  title_en,
  title_ar,
  date,
  views,
  status,
  handleModal,
  actions,
}) => (
  <div className="video-card" onClick={handleModal}>
    {actions && <div className="video-card__actions">{actions}</div>}
    <div className="video-card__image">
      <video className="video-card__img">
        <source alt={title_en} src={video} type="video/mp4" />
      </video>
      <div className="video-card__playbtn">
        <Icon name="chevron circle right" size="huge" />
      </div>
    </div>
    <div className="video-card__details">
      <Header className="video-card__title" as="h4">
        {title_en}
      </Header>
      {title_ar && <div className="video-card__details--item">{title_ar}</div>}
      {status && <div className="video-card__details--item">{status}</div>}
    </div>
  </div>
)

IntroVideoCard.propTypes = {
  video: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  views: PropTypes.string,
  status: PropTypes.any,
}

IntroVideoCard.defaultProps = {
  video: '',
  title: null,
  date: null,
  views: null,
  status: null,
}
export default IntroVideoCard
