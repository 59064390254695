import React from 'react'
import PropTypes from 'prop-types'
import AppContainer from 'templates/AppContainer'
import Form from './Form'

const Create = ({ handleSubmit, error, fetching, onCancel, t }) => (
  <AppContainer header={t('Add Page')}>
    <Form
      t={t}
      onSubmit={handleSubmit}
      errorMessage={error}
      fetching={fetching}
      onCancel={onCancel}
    />
  </AppContainer>
)

export default Create

Create.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  error: PropTypes.string,
}

Create.defaultProps = {
  fetching: false,
  error: null,
}
