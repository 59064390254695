import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Dropdown as SemDropdown, Icon } from 'semantic-ui-react'

const Dropdown = ({ t, icon, id, url, onDelete }) => (
  <SemDropdown trigger={<Icon name={icon} />} icon={null} direction="left">
    <SemDropdown.Menu>
      <SemDropdown.Item
        as={Link}
        icon="eye"
        text={t('Details')}
        to={`${url}/${id}/details`}
      />
      <SemDropdown.Item
        as={Link}
        icon="pencil alternate"
        text={t('Edit')}
        to={`${url}/${id}/edit`}
      />
    </SemDropdown.Menu>
  </SemDropdown>
)

Dropdown.propTypes = {
  icon: PropTypes.string,
  id: PropTypes.number.isRequired,
  t: PropTypes.func,
  url: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
}

Dropdown.defaultProps = {
  icon: '',
  t: null,
}

export default Dropdown
