import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader } from 'semantic-ui-react'
import AppContainer from 'templates/AppContainer'
import Form from '../Create/Form'

const formFields = data => {
  const values = { ...data }
  values.channel_id = data.channel && data.channel.id
  values.status =
    values.status === 0 ? '0' : values.status && values.status.toString()
  return values
}

const Update = ({
  handleSubmit,
  onChannesListChange,
  fetch_channels,
  error,
  fetching,
  fetching_video,
  options,
  video,
  channels,
  onCancel,
  t,
}) => {
  const channelsOPtions = channels.filter(
    channel => channel.id !== (video.channel && video.channel.id),
  )
  const _channels = [
    {
      id: video.channel && video.channel.id,
      title: video.channel && video.channel.title,
    },
    ...channelsOPtions,
  ]
  return (
    <AppContainer header={t('Update Video')}>
      {fetching_video && (
        <Dimmer inverted active>
          <Loader size="medium">Fetching Video data...</Loader>
        </Dimmer>
      )}
      <Form
        t={t}
        initialValues={formFields(video)}
        options={options}
        onSubmit={handleSubmit}
        channels={_channels}
        onChannesListChange={onChannesListChange}
        errorMessage={error}
        fetching={fetching}
        fetch_channels={fetch_channels}
        fetching_video={fetching_video}
        onCancel={onCancel}
      />
    </AppContainer>
  )
}

export default Update

Update.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  fetching_video: PropTypes.bool,
  fetch_channels: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.object,
  video: PropTypes.object,
  onChannesListChange: PropTypes.func,
  channels: PropTypes.array,
}

Update.defaultProps = {
  fetching: false,
  fetching_video: false,
  fetch_channels: false,
  error: null,
  options: {},
  channels: [],
  onChannesListChange: () => {},
  video: {},
}
