import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Create from './Create'
import Update from './Update'
import List from './List'

export default () => (
  <Switch>
    <Route exact path="/videos/:id/edit" component={Update} />
    <Route exact path="/videos/create" component={Create} />
    <Route exact path="/videos" component={List} />
  </Switch>
)
