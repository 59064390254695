import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const pageTypes = { about_us: 1, terms: 2 }

const HOC = WrappedComponent => {
  class AboutHoc extends React.Component {
    componentWillMount() {
      const { getPages, match } = this.props
      const about_app_type = pageTypes[match.params.page_type]
      const locale = match.params.locale && match.params.locale
      getPages({
        params: {
          about_app_type,
          locale,
        },
      })
    }

    render() {
      const { pages } = this.props
      const pageData = pages.about_apps && pages.about_apps.map(p => p)[0]
      return <WrappedComponent {...this.props} data={pageData} />
    }
  }

  AboutHoc.propTypes = {
    getPages: PropTypes.func.isRequired,
    pages: PropTypes.object,
    match: PropTypes.object,
  }

  AboutHoc.defaultProps = {
    pages: {},
    match: {},
  }

  const mapStateToProps = ({ api }) => ({
    pages: api.mobile_pages || {},
    loading: api.requests.mobile_pages.get.loading,
    fetching: api.requests.mobile_pages.get.fetching,
    error: api.requests.mobile_pages.get.error,
  })

  const mapDispatchToProps = dispatch => ({
    getPages: props => dispatch({ type: 'GET_MOBILE_PAGES', ...props }),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AboutHoc)
}
export default HOC
