import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'
import swal from 'sweetalert'
import Actions from './Actions'

const warrningMessages = [
  {
    title: 'Deactive IP Whitelist',
    text: 'are you sure you want to Deactive the IP Whitelist ?',
    btn: 'Yes, Deactive it!',
    successTitle: 'Deactivated',
  },
  {
    title: 'Active IP Whitelist',
    text: 'are you sure you want to Active the IP Whitelist ?',
    btn: 'Yes, Active it!',
    successTitle: 'Activated!',
  },
]
warrningMessages[2] = warrningMessages[1]
warrningMessages[1] = warrningMessages[0]

const HOC = WrappedComponent => {
  class IPWhitelistsHoc extends React.Component {
    state = {
      activePage: 1,
    }

    componentWillMount() {
      const { getIPWhitelists, getIPWhitelistsSetting } = this.props
      getIPWhitelists({
        params: { limit: 12 },
      })
      getIPWhitelistsSetting()
    }

    onDeleteSuccess = res => {
      const { getIPWhitelists } = this.props
      Toaster.success(res.data.message)
      getIPWhitelists({ params: { limit: 12 } })
    }

    onDelete = id => {
      const { deleteUser } = this.props
      if (window.confirm('Do you want to delete this IP Whitelist?') === true) {
        deleteUser(id, { onFulfilled: this.onDeleteSuccess })
      }
    }

    handlePaginationChange = (e, { activePage }) => {
      const { getIPWhitelists } = this.props
      this.setState({ activePage })
      getIPWhitelists({ params: { page: activePage, limit: 12 } })
    }

    onEdit = id => {
      this.props.history.go(`/ip-whitelists/${id}/edit`)
    }

    onShow = id => {
      this.props.history.go(`/ip-whitelists/${id}/show`)
    }

    handleDelete = id => {
      const { deleteIPWhitelist } = this.props

      swal({
        title: 'Are you sure?',
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          catch: {
            text: 'Yes, Delete',
            closeModal: false,
          },
        },
      })
        .then(confirm => {
          if (!confirm) throw null
          return new Promise((resolve, reject) => {
            const onFulfilled = respo => {
              resolve(respo.data.message)
              this.props.getIPWhitelists()
            }
            const onFaild = res => {
              reject(res)
            }
            deleteIPWhitelist(id, { onFulfilled, onFaild })
          })
        })
        .then(res => {
          swal({
            title: 'Deleted!',
            text: res,
            icon: 'success',
            button: false,
            timer: 2000,
          })
        })
        .catch(err => {
          if (err) {
            swal({
              title: 'Error',
              text: err,
              icon: 'error',
              button: false,
            })
          } else {
            swal.stopLoading()
            swal.close()
          }
        })
    }

    handleChange = value => {
      const isTrue = value ? 1 : 2
      const { updateIPWhitelistsSetting } = this.props
      const msg = warrningMessages[isTrue]

      swal({
        title: msg.title,
        text: msg.text,
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          catch: {
            text: msg.btn,
            closeModal: false,
          },
        },
      })
        .then(confirm => {
          if (!confirm) throw null
          return new Promise((resolve, reject) => {
            const onFulfilled = respo => {
              resolve(respo.data.message)
              this.props.getIPWhitelists({
                params: { limit: 12 },
              })
            }

            const onFaild = res => {
              reject(res)
            }
            const theValue = value ? 't' : 'f'
            updateIPWhitelistsSetting(theValue, { onFulfilled, onFaild })
          })
        })
        .then(res => {
          swal({
            title: msg.successTitle,
            text: res,
            icon: 'success',
            button: false,
            timer: 2000,
          })
        })
        .catch(err => {
          if (err) {
            swal({
              title: 'Error',
              text: err,
              icon: 'error',
              button: false,
            })
          } else {
            swal.stopLoading()
            swal.close()
          }
        })
    }

    render() {
      const {
        t,
        ip_whitelists,
        loading,
        error,
        ip_whitelist_setting,
      } = this.props
      const ipVhitelistValue =
        ip_whitelist_setting.setting && ip_whitelist_setting.setting.value
      const { activePage } = this.state
      const headers = [
        { name: t('Start IP'), key: 'start_ip' },
        { name: t('End IP'), key: 'end_ip' },
        {
          name: t('Actions'),
          render: row => (
            <Actions
              id={row.id}
              onEdit={() => this.onEdit(row.id)}
              onShow={() => this.onShow(row.id)}
              onDelete={() => this.handleDelete(row.id)}
            />
          ),
          props: { width: 2 },
        },
      ]
      return (
        <WrappedComponent
          {...this.state}
          headers={headers}
          data={ip_whitelists && ip_whitelists.ip_whitelists}
          loading={loading}
          error={error}
          activePage={activePage}
          handlePaginationChange={this.handlePaginationChange}
          {...this.props}
          pagination={ip_whitelists.pagination}
          ipVhitelistValue={ipVhitelistValue}
          handleChange={this.handleChange}
        />
      )
    }
  }

  IPWhitelistsHoc.propTypes = {
    t: PropTypes.func.isRequired,
    getIPWhitelists: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    ip_whitelists: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string,
  }

  IPWhitelistsHoc.defaultProps = {
    ip_whitelists: {},
    loading: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    ip_whitelists: api.ip_whitelists || {},
    ip_whitelist_setting: api.ip_whitelist_setting || {},
    loading: api.requests.ip_whitelists.get.loading,
    fetching: api.requests.ip_whitelists.get.fetching,
    fetching_ip_whitelist_setting:
      api.requests.ip_whitelist_setting.get.fetching,
    error: api.requests.ip_whitelists.get.error,
    error_ip_whitelist_setting: api.requests.ip_whitelists.get.error,
  })

  const mapDispatchToProps = dispatch => ({
    getIPWhitelists: props => dispatch({ type: 'GET_IP_WHITELISTS', ...props }),

    deleteIPWhitelist: (id, props = {}) =>
      dispatch({
        type: 'DELETE_IP_WHITELIST',
        vars: { id },
        ...props,
      }),

    getIPWhitelistsSetting: props =>
      dispatch({
        type: 'GET_IP_WHITELISTS_SETTING',
        ...props,
      }),

    updateIPWhitelistsSetting: (data, props) =>
      dispatch({
        type: 'UPDATE_IP_WHITELIST_SETTING',
        data: { setting: { value: data } },
        ...props,
      }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(IPWhitelistsHoc))
}

export default HOC
