import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router'
import { Dropdown, Icon } from 'semantic-ui-react'
import { Cards, Card } from 'atoms/Cards'
import ThemeCard from 'templates/ThemeCard'
import Status from 'atoms/Status'

const Themes = ({ themes, handleDelete, handleStatus }) => (
  <Cards gap="13px" cardWidth="300px">
    {themes.map(theme => (
      <Card key={theme.id}>
        <ThemeCard
          onClick={() => (window.location = `#/themes/${theme.id}/show`)}
          image={theme.recommended_url}
          title_en={theme.title_en || 'No Title'}
          title_ar={theme.title_ar || 'No Title'}
          color={theme.color || ''}
          status={<Status name={theme.status_label.toLowerCase()} />}
          actions={
            <Dropdown
              direction="left"
              icon={null}
              trigger={<Icon name="ellipsis horizontal" size="large" />}
            >
              <Dropdown.Menu>
                {theme.id !== 1 && (
                  <Dropdown.Item
                    icon="pencil alternate"
                    text="Edit"
                    as={Link}
                    to={`/themes/${theme.id}/edit`}
                  />
                )}
                <Dropdown.Item
                  icon="eye"
                  text="Theme Details"
                  as={Link}
                  to={`/themes/${theme.id}/show`}
                />
                {theme.status === 2 && (
                  <Dropdown.Item
                    icon="eye slash"
                    text="Deactive"
                    onClick={() => handleStatus(theme.id, 1)}
                  />
                )}
                {theme.status !== 2 && (
                  <Dropdown.Item
                    icon="check"
                    text="Active"
                    onClick={() => handleStatus(theme.id, 2)}
                  />
                )}
                {theme.id !== 1 && (
                  <Dropdown.Item
                    icon="trash alternate outline"
                    text="Delete"
                    onClick={() => handleDelete(theme.id)}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          }
        />
      </Card>
    ))}
  </Cards>
)

export default Themes
