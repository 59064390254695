import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Wrapper from 'templates/Wrapper'
import NotFound from 'pages/NotFound'

// Modules
import Channels from 'pages/Channels'
import Users from 'pages/Users'
import Videos from 'pages/Videos'
import Home from 'pages/Home'
import Notifications from 'pages/Notifications'
import Pages from 'pages/Pages'
import Dashboard from 'pages/Dashboard'
import IPWhitelists from 'pages/IPWhitelists'
import Themes from 'pages/Themes'
import CustomChannels from 'pages/CustomChannels'
import IntroVideos from 'pages/IntroVideos'
import RecommendationConfig from 'pages/RecommendationConfig'

const ModulesRouters = () => (
  <Wrapper>
    <Route>
      <Switch>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/users" component={Users} />
        <Route path="/channels" component={Channels} />
        <Route path="/videos" component={Videos} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/pages" component={Pages} />
        <Route path="/ip-whitelists" component={IPWhitelists} />
        <Route path="/themes" component={Themes} />
        <Route path="/custom-channels" component={CustomChannels} />
        <Route path="/intro-videos" component={IntroVideos} />
        <Route path="/recommendation-config" component={RecommendationConfig} />
        <Route path="/" component={Home} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Route>
  </Wrapper>
)

export default ModulesRouters
