import React from 'react'
import PropTypes from 'prop-types'
import AppContainer from 'templates/AppContainer'
import Form from './Form'

const Create = ({
  handleSubmit,
  onChannesListChange,
  error,
  fetching,
  fetch_channels,
  options,
  channels,
  onCancel,
  t,
}) => (
  <AppContainer header={t('Add Video')}>
    <Form
      t={t}
      options={options}
      onSubmit={handleSubmit}
      channels={channels}
      onChannesListChange={onChannesListChange}
      errorMessage={error}
      fetching={fetching}
      fetch_channels={fetch_channels}
      isCreate
      onCancel={onCancel}
    />
  </AppContainer>
)

export default Create

Create.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.object,
  onChannesListChange: PropTypes.func,
  channels: PropTypes.array,
}

Create.defaultProps = {
  fetching: false,
  error: null,
  options: {},
  channels: [],
  onChannesListChange: () => {},
}
