import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'

const HOC = WrappedComponent => {
  class Show extends React.Component {
    componentWillMount() {
      const { getIPWhitelist, match } = this.props
      getIPWhitelist(match.params.id)
    }

    handleOnClose = () => {
      const { history } = this.props
      history.goBack()
    }

    render() {
      const { ip_whitelist } = this.props
      return (
        <WrappedComponent
          onClose={this.handleOnClose}
          {...this.props}
          ip_whitelist={ip_whitelist.ip_whitelist}
        />
      )
    }
  }

  Show.propTypes = {
    t: PropTypes.func.isRequired,
    getIPWhitelist: PropTypes.func.isRequired,
    ip_whitelist: PropTypes.object,
    match: PropTypes.isRequired,
    fetching: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.func.isRequired,
  }

  Show.defaultProps = {
    ip_whitelist: {},
    fetching: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    ip_whitelist: api.ip_whitelist || {},
    loading: api.requests.ip_whitelist.get.loading,
    fetching: api.requests.ip_whitelist.get.fetching,
    error: api.requests.ip_whitelist.get.error,
  })

  const mapDispatchToProps = dispatch => ({
    getIPWhitelist: (id, props) =>
      dispatch({ type: 'GET_IP_WHITELIST', vars: { id }, ...props }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Show))
}

export default HOC
