import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../atoms/Modal'
import Form from './Form'

const Create = ({ onClose, handleSubmit, error, fetching, t }) => (
  <Modal
    size="tiny"
    open
    close={onClose}
    headerIcon="unlock alternate"
    headerContent="Add IP Whitelist"
  >
    <Form
      t={t}
      onSubmit={handleSubmit}
      errorMessage={error}
      fetching={fetching}
      isCreate
    />
  </Modal>
)

export default Create

Create.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  error: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

Create.defaultProps = {
  fetching: false,
  error: null,
}
