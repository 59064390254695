import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Header, Button, Icon, Dimmer, Loader } from 'semantic-ui-react'

import AppContainer from '../../../templates/AppContainer'
import ChannelInfo from './Widgets/ChannelInfo'
import Videos from './Videos'

const channelStatus = {
  0: { label: 'Active', icon: 'eye' },
  1: { label: 'Active', icon: 'eye' },
  2: { label: 'Deactive', icon: 'eye slash' },
}
const Show = ({ channel, fetching, t, channel_id, handleDelete }) => (
  <div style={{ position: 'relative' }}>
    <AppContainer
      header={t('Channel Details')}
      button={
        <div>
          <Button
            icon="trash alternate"
            basic
            color="red"
            content="Delete"
            onClick={handleDelete}
          />
          <Button
            icon="pencil alternate"
            basic
            color="violet"
            content="Edit"
            as={Link}
            to={`/channels/${channel_id}/edit`}
          />
        </div>
      }
    >
      {fetching && (
        <Dimmer inverted active={true}>
          <Loader size="medium">Fetching channel data...</Loader>
        </Dimmer>
      )}
      <Grid divided="vertically">
        <Grid.Row columns={2}>
          <Grid.Column width={16}>
            <ChannelInfo channel={channel} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Videos channel_id={channel_id} channelLoading={fetching} />
    </AppContainer>
  </div>
)

export default Show
