import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../atoms/Modal'
import Form from './Form'

const Create = ({
  onClose,
  handleSubmit,
  error,
  fetching,
  t,
  users,
  fetch_users,
  usersSearch,
}) => (
  <Modal
    size="tiny"
    open={true}
    close={onClose}
    headerIcon="bell outline"
    headerContent="Send Notifications"
  >
    <Form
      t={t}
      onSubmit={handleSubmit}
      errorMessage={error}
      fetching={fetching}
      users={users}
      fetch_users={fetch_users}
      usersSearch={usersSearch}
    />
  </Modal>
)

export default Create

Create.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  error: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

Create.defaultProps = {
  fetching: false,
  error: null,
}
