import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'
import Dropdown from 'atoms/Dropdown'

const HOC = WrappedComponent => {
  class UsersHoc extends React.Component {
    state = { activePage: 1 }

    componentWillMount() {
      const { getUsers } = this.props
      getUsers({ params: { limit: 12 }, onFulfilled: this.onSuccessLogin })
    }

    onDeleteSuccess = res => {
      const { getUsers } = this.props
      Toaster.success(res.data.message)
      getUsers({ params: { limit: 12 } })
    }

    onDelete = id => {
      const { deleteUser } = this.props
      if (window.confirm('Do you want to delete this user?') === true) {
        deleteUser(id, { onFulfilled: this.onDeleteSuccess })
      }
    }

    handlePaginationChange = (e, { activePage }) => {
      const { getUsers } = this.props
      this.setState({ activePage })
      getUsers({ params: { page: activePage, limit: 12 } })
    }

    render() {
      const { t, users, loading, error } = this.props
      const { activePage } = this.state
      const headers = [
        { name: t('Name'), key: 'full_name' },
        { name: t('Username'), key: 'username' },
        {
          name: t('Actions'),
          render: row => (
            <Dropdown
              direction="left"
              icon="ellipsis vertical"
              t={t}
              id={row.id}
              url="/users"
              onDelete={() => this.onDelete(row.id)}
            />
          ),
          props: { width: 2 },
        },
      ]
      return (
        <WrappedComponent
          headers={headers}
          data={users && users.users}
          loading={loading}
          error={error}
          activePage={activePage}
          handlePaginationChange={this.handlePaginationChange}
          {...this.props}
          pagination={users.pagination}
        />
      )
    }
  }

  UsersHoc.propTypes = {
    t: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    users: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string,
  }

  UsersHoc.defaultProps = {
    users: {},
    loading: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    users: api.users || {},
    loading: api.requests.users.get.loading,
    fetching: api.requests.users.get.fetching,
    error: api.requests.users.get.error,
  })

  const mapDispatchToProps = dispatch => ({
    getUsers: props => dispatch({ type: 'GET_USERS', ...props }),
    deleteUser: (id, props = {}) =>
      dispatch({ type: 'DELETE_USER', vars: { id }, ...props }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(UsersHoc))
}

export default HOC
