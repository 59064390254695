import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Button, Grid, Message, Dimmer, Loader } from 'semantic-ui-react'
import reduxForm from 'atoms/reduxForm'
import textInput from 'molecules/reduxFormFields/textInput'
import dropdownInput from 'molecules/reduxFormFields/dropdownInput'

const Form = ({
  handleSubmit,
  onCancel,
  validate,
  t,
  options,
  fetching,
  errorMessage,
  isCreate,
}) => {
  const categories =
    options.categories &&
    options.categories.map(cat => ({ text: cat.title, value: cat.id }))
  const age_groups =
    options.age_groups &&
    options.age_groups.map(age => ({ text: age.title, value: age.id }))
  const statuses =
    options.statuses &&
    options.statuses.map(status => ({ text: status.name, value: status.id }))
  return (
    <React.Fragment>
      {errorMessage && (
        <Message negative className="channel-form--error-message">
          {errorMessage}
        </Message>
      )}
      <Grid doubling columns={2}>
        {fetching && (
          <Dimmer inverted active>
            <Loader size="medium">Processing...</Loader>
          </Dimmer>
        )}
        <Grid.Row>
          <Grid.Column width={4} className="channel-form_field">
            <Field
              validate={[validate.required]}
              name="youtube_id"
              title={t('Channel ID')}
              component={textInput}
            />
          </Grid.Column>
          <Grid.Column width={6} className="channel-form_field">
            <Field
              validate={[validate.required]}
              name="category_id"
              title={t('Channel Category')}
              options={categories}
              component={dropdownInput}
              search
            />
          </Grid.Column>
          <Grid.Column width={6} className="channel-form_field">
            <Field
              validate={[validate.required]}
              name="age_group_ids"
              title={t('Audiance & Age Group')}
              options={age_groups}
              multiple
              component={dropdownInput}
              search
            />
          </Grid.Column>
        </Grid.Row>
        {!isCreate && (
          <Grid.Row>
            <Grid.Column width={4} className="channel-form_field">
              <Field
                name="status"
                title={t('Status')}
                options={statuses}
                component={dropdownInput}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width={16}>
            <Button primary disabled={fetching} onClick={handleSubmit}>
              {t('Save')}
            </Button>
            <Button color="grey" onClick={onCancel}>
              {t('Cancel')}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  )
}

Form.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  validate: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  isCreate: PropTypes.bool,
  errorMessage: PropTypes.string,
  options: PropTypes.object,
}
Form.defaultProps = {
  fetching: false,
  isCreate: false,
  errorMessage: null,
  options: {},
}
export default reduxForm({
  form: 'channels_form',
  enableReinitialize: true,
})(Form)
