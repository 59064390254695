import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import getData from './getData'

const TableData = ({ data, headers }) => (
  <Table.Body>
    {data &&
      data.map((record, record_index) => (
        <Table.Row key={record_index}>
          {headers.map((head, head_index) => (
            <Table.Cell {...head.tdProps} key={`${record_index}-${head_index}`}>
              {head.render ? head.render(record) : getData(record, head.key)}
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
  </Table.Body>
)

TableData.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string,
      render: PropTypes.func,
    }),
  ).isRequired,
  data: PropTypes.array,
}

TableData.defaultProps = {
  data: [],
}

export default TableData
