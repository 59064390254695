import React from 'react'
import { Divider } from 'semantic-ui-react'
import AppContainer from 'templates/AppContainer'
import Table from '../../../atoms/Table'

const Container = ({ pages, fetching, error, headers }) => (
  <AppContainer header="Pages">
    <Divider />
    <Table headers={headers} data={pages} loading={fetching} error={error} />
  </AppContainer>
)

export default Container
