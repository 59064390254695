import React from 'react'
import { Image, Message, Grid, Header, Dimmer, Loader } from 'semantic-ui-react'
import Pagination from 'molecules/Pagination'

import Filters from './Filters'
import VideosList from './VideosList'

const Videos = ({
  videos,
  noVideos,
  fetching,
  channelLoading,
  handleFilters,
  options,
  pagination,
  filters,
  handleDelete,
}) => (
  <React.Fragment>
    <Header as="h3">Channel Videos</Header>
    <Grid divided="vertically">
      <Grid.Row columns={2}>
        <Filters handleFilters={handleFilters} options={options} />
      </Grid.Row>
    </Grid>
    {noVideos && <Message warning header="No videos founded!" />}
    <div style={{ position: 'relative' }}>
      {!channelLoading && fetching && (
        <Dimmer inverted active={true}>
          <Loader size="medium" />
        </Dimmer>
      )}

      <VideosList videos={videos} handleDelete={handleDelete} />

      {pagination.total_entries > filters.limit && (
        <div style={{ padding: '30px 0' }}>
          <Pagination
            total={pagination.total_entries}
            page={filters.page}
            showing={filters.limit}
            onChange={page => handleFilters('page', page)}
          />
        </div>
      )}
    </div>
  </React.Fragment>
)

export default Videos
