import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown as SemDropdown } from 'semantic-ui-react'

const Dropdown = ({
  title,
  input: { onChange, value },
  meta: { error, touched },
  options,
  loading,
  onSearch,
  ...props
}) => (
  <div>
    <div className="tuby-input__label">{title}</div>
    <SemDropdown
      {...props}
      fluid
      selection
      loading={loading}
      value={value}
      onChange={(event, data) => onChange(data.value)}
      onSearchChange={(event, { searchQuery }) => onSearch(searchQuery)}
      options={options || []}
    />
    {error && touched && <div className="tuby-input--error">{error}</div>}
  </div>
)

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array,
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
}

Dropdown.defaultProps = {
  options: [],
  loading: false,
  onSearch: () => {},
}

export default Dropdown
