import React from 'react'
import { Switch, Route } from 'react-router-dom'
import About from './About'

export default () => (
  <Switch>
    <Route path="/about/:page_type/:locale" component={About} />
    <Route path="/about/:page_type" component={About} />
    <Route path="/about" component={About} />
  </Switch>
)
