import React from 'react'
import { Link, Route } from 'react-router-dom'
import {
  Image,
  Message,
  Grid,
  Header,
  Dimmer,
  Loader,
  Button,
  Divider,
} from 'semantic-ui-react'

import Pagination from 'molecules/Pagination'
import AppContainer from 'templates/AppContainer'
import Filters from './Filters'
import CustomChannels from './CustomChannels'
import Edit from '../Edit'

const Container = ({
  channels,
  noCustomChannels,
  fetching,
  channelLoading,
  handleFilters,
  pagination,
  filters,
  channel_options,
  handleStatus,
}) => (
  <AppContainer header="Custom Channels">
    <Route exact component={Edit} path="/custom-channels/:id" />
    <Grid divided="vertically">
      <Grid.Row columns={2}>
        <Filters
          handleFilters={handleFilters}
          channel_options={channel_options}
          filters={filters}
        />
      </Grid.Row>
    </Grid>
    <Divider />
    {noCustomChannels && <Message warning header="No channel founded!" />}
    <div style={{ position: 'relative' }}>
      {!channelLoading && fetching && (
        <Dimmer inverted active>
          <Loader size="medium" />
        </Dimmer>
      )}

      <CustomChannels channels={channels} handleStatus={handleStatus} />

      {pagination.total_entries > filters.limit && (
        <div style={{ padding: '30px 0' }}>
          <Pagination
            total={pagination.total_entries}
            page={filters.page}
            showing={filters.limit}
            onChange={page => handleFilters('page', page)}
          />
        </div>
      )}
    </div>
  </AppContainer>
)

export default Container
