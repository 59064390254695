import React, { Component } from 'react'
import { connect } from 'react-redux'

const HOC = WrappedComponent => {
  class ViewsByCategory extends Component {
    state = {
      filters: {
        last_week: null,
        all_time: null,
      },
    }

    handleFilters = (key, value) => {
      const { filters } = this.state
      filters[key] = value
      this.setState({ filters })
      this.loadViewsByCategory(filters)
    }

    loadViewsByCategory = (filters = this.state.filters) => {
      const { getViewsByCategory } = this.props
      getViewsByCategory({ params: filters })
    }

    componentDidMount() {
      // this.loadViewsByCategory()
    }

    render() {
      // const viewsByCategory = this.props.viewsByCategory.viewsByCategory || []
      const viewsByCategory = this.props.categories || []

      return (
        <WrappedComponent
          {...this.props}
          viewsByCategory={viewsByCategory}
          filters={this.state.filters}
          // noViewsByCategory={
          //   !this.props.fetching && viewsByCategory.length <= 0
          // }
          noViewsByCategory={viewsByCategory.length <= 0}
          handleFilters={this.handleFilters}
        />
      )
    }
  }

  const mapStateToProps = ({ api }) => ({
    viewsByCategory: api.viewsByCategory || {},
    fetching: api.requests.viewsByCategory, // .get.fetching,
    loading: api.requests.viewsByCategory, // .get.loading,
    error: api.requests.viewsByCategory, // .get.error,
  })

  const mapDispatchToProps = dispatch => ({
    getViewsByCategory: props =>
      dispatch({
        type: 'GET_VIEWS_BY_CATEGORY',
        ...props,
      }),
  })
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ViewsByCategory)
}

export default HOC
