import Toastr from 'toastr'
import logOut from '../functions/auth'

const config = {
  host: 'https://api.tuby.dev.clicksandbox.com/v1',
  // host: 'http://192.168.3.28:3000/v1',
  headers: {
    Accept: 'application/json',
    'content-type': 'application/json',
    'cache-control': 'reload',
    Authorization: `Bearer  ${localStorage.getItem('token')}`,
  },
  data_error: 'error',
  onFaild: err => {
    Toastr.error(err)
    if (
      err.split(/\s+|\./).includes('Signature') ||
      err.split(/\s+|\./).includes('segments')
    ) {
      logOut()
    }
  },
  endpoints: [
    {
      url: 'users',
      method: 'get',
      id: 'users',
      action: 'GET_USERS',
      response: 'data',
    },
    {
      url: 'users/:id',
      method: 'get',
      id: 'user',
      action: 'GET_USER',
      response: 'data',
    },
    {
      url: 'users',
      method: 'post',
      id: 'user',
      action: 'CREATE_USER',
    },
    {
      url: 'auth/login',
      method: 'post',
      id: 'auth',
      action: 'USER_LOGIN',
      headers: {
        accept: 'application/json',
      },
    },
    {
      url: 'channels',
      method: 'get',
      id: 'channels',
      action: 'GET_CHANNELS',
      response: 'data',
    },
    {
      url: 'channels/:channel_id',
      method: 'get',
      id: 'channel',
      action: 'GET_CHANNEL',
      response: 'data.channel',
    },
    {
      url: 'users/:id',
      method: 'put',
      id: 'user',
      action: 'UPDATE_USER',
      response: 'data',
    },
    {
      url: 'users/:id',
      method: 'delete',
      id: 'user',
      action: 'DELETE_USER',
    },
    {
      url: 'channels?status=0',
      method: 'get',
      id: 'in_active_channels',
      action: 'GET_INACTIVECHANNELS',
      response: 'data',
    },
    {
      url: 'channels/options',
      method: 'get',
      id: 'channels_options',
      action: 'GET_CHANNELS_OPTIONS',
      response: 'data',
    },
    {
      url: 'channels',
      method: 'post',
      id: 'channel',
      action: 'CREATE_CHANNEL',
    },
    {
      url: 'channels/:id',
      method: 'put',
      id: 'channel',
      action: 'UPDATE_CHANNEL',
      response: 'data',
    },
    {
      url: 'videos/options',
      method: 'get',
      id: 'videos_options',
      action: 'GET_VIDEOS_OPTIONS',
      response: 'data',
    },
    {
      url: 'videos',
      method: 'post',
      id: 'video',
      action: 'CREATE_VIDEO',
    },
    {
      url: 'videos/:id',
      method: 'put',
      id: 'video',
      action: 'UPDATE_VIDEO',
      response: 'data',
    },
    {
      url: 'videos/:id',
      method: 'get',
      id: 'video',
      action: 'GET_VIDEO',
      response: 'data',
    },
    {
      url: 'videos',
      method: 'get',
      id: 'videos',
      action: 'GET_VIDEOS',
      response: 'data',
    },
    {
      url: 'videos/:video_id',
      method: 'delete',
      id: 'video',
      action: 'DELETE_VIDEO',
      response: 'data',
    },
    {
      url: 'channels/:id',
      method: 'delete',
      id: 'channel',
      action: 'DELETE_CHANNEL',
      response: 'data',
    },
    {
      url: 'channels/options',
      method: 'get',
      id: 'options',
      action: 'GET_OPTIONS',
      response: 'data',
    },
    {
      url: 'notifications',
      method: 'get',
      id: 'notifications',
      action: 'GET_NOTIFICATIONS',
      response: 'data',
    },
    {
      url: 'notifications',
      method: 'post',
      id: 'notification',
      action: 'CREATE_NOTIFICATION',
    },
    {
      url: 'about_app',
      method: 'get',
      id: 'pages',
      action: 'GET_PAGES',
      response: 'data',
    },
    {
      url: 'about_app',
      method: 'post',
      id: 'page',
      action: 'CREATE_PAGE',
    },
    {
      url: 'about_app/:id',
      method: 'put',
      id: 'page',
      action: 'UPDATE_PAGE',
    },
    {
      url: 'about_app/:id',
      method: 'get',
      id: 'page',
      action: 'GET_PAGE',
      response: 'data',
    },
    {
      url: 'mob/about_app',
      method: 'get',
      id: 'mobile_pages',
      action: 'GET_MOBILE_PAGES',
      response: 'data',
    },
    {
      url: 'ip_whitelists',
      method: 'get',
      id: 'ip_whitelists',
      action: 'GET_IP_WHITELISTS',
      response: 'data',
    },
    {
      url: 'ip_whitelists/:id',
      method: 'get',
      id: 'ip_whitelist',
      action: 'GET_IP_WHITELIST',
      response: 'data',
    },
    {
      url: 'ip_whitelists',
      method: 'post',
      id: 'ip_whitelist',
      action: 'CREATE_IP_WHITELIST',
    },
    {
      url: 'ip_whitelists/:id',
      method: 'put',
      id: 'ip_whitelist',
      action: 'UPDATE_IP_WHITELIST',
      response: 'data',
    },
    {
      url: 'ip_whitelists/:id',
      method: 'delete',
      id: 'ip_whitelist',
      action: 'DELETE_IP_WHITELIST',
      response: 'data',
    },
    {
      url: 'dashboreds',
      method: 'get',
      id: 'dashboard',
      action: 'GET_DASHBOARD',
      response: 'data',
    },

    // themes

    {
      url: 'themes',
      method: 'get',
      id: 'themes',
      action: 'GET_THEMES',
      response: 'data',
    },
    {
      url: 'themes/:theme_id',
      method: 'get',
      id: 'theme',
      action: 'GET_THEME',
      response: 'data.theme',
    },
    {
      url: 'themes',
      method: 'post',
      id: 'theme',
      action: 'CREATE_THEME',
    },
    {
      url: 'themes/:id',
      method: 'put',
      id: 'theme',
      action: 'UPDATE_THEME',
      response: 'data',
    },
    {
      url: 'themes/:id',
      method: 'delete',
      id: 'theme',
      action: 'DELETE_THEME',
      response: 'data',
    },
    {
      url: 'themes/options',
      method: 'get',
      id: 'themes_options',
      action: 'GET_THEMES_OPTIONS',
      response: 'data',
    },

    // intro videos

    {
      url: 'intro_videos',
      method: 'get',
      id: 'intro_videos',
      action: 'GET_INTRO_VIDEOS',
      response: 'data',
    },
    {
      url: 'intro_videos/:id',
      method: 'get',
      id: 'intro_video',
      action: 'GET_INTRO_VIDEO',
      response: 'data.intro_video',
    },
    {
      url: 'intro_videos',
      method: 'post',
      id: 'intro_video',
      action: 'CREATE_INTRO_VIDEO',
    },
    {
      url: 'intro_videos/:id',
      method: 'put',
      id: 'intro_video',
      action: 'UPDATE_INTRO_VIDEO',
      response: 'data',
    },
    {
      url: 'intro_videos/:id',
      method: 'delete',
      id: 'theme',
      action: 'DELETE_INTRO_VIDEO',
      response: 'data',
    },
    {
      url: 'intro_videos/options',
      method: 'get',
      id: 'intro_videos_options',
      action: 'GET_INTRO_VIDEOS_OPTIONS',
      response: 'data',
    },

    {
      url: 'recommendation_ratios',
      method: 'get',
      id: 'recommendation_config',
      action: 'GET_RECOMMENDATION_CONFIG',
      response: 'data',
    },
    {
      url: 'recommendation_ratios',
      method: 'put',
      id: 'recommendation_config',
      action: 'UPDATE_RECOMMENDATION_CONFIG',
      response: 'data.recommendation_ratio',
    },

    {
      url: 'settings/ip_whitelists',
      method: 'get',
      id: 'ip_whitelist_setting',
      action: 'GET_IP_WHITELISTS_SETTING',
      response: 'data',
    },
    {
      url: 'settings/ip_whitelists',
      method: 'put',
      id: 'ip_whitelist_setting',
      action: 'UPDATE_IP_WHITELIST_SETTING',
      response: 'data',
    },
  ],
}

export default config
