import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  const Create = props => {
    const { fetching, error, history, createUser, getUsers } = props
    const handleOnClose = () => {
      history.goBack()
    }

    const onSuccess = res => {
      Toaster.success(res.data.message)
      getUsers({
        params: { limit: 12 },
      })
      history.goBack()
    }

    const handleSubmit = values => {
      createUser(values, { onFulfilled: onSuccess })
    }

    return (
      <WrappedComponent
        fetching={fetching}
        error={error}
        onClose={handleOnClose}
        handleSubmit={handleSubmit}
        {...props}
      />
    )
  }

  Create.propTypes = {
    t: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
  }

  Create.defaultProps = {
    fetching: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    loading: api.requests.user.post.loading,
    fetching: api.requests.user.post.fetching,
    error: api.requests.user.post.error,
  })

  const mapDispatchToProps = dispatch => ({
    createUser: (data, props = {}) =>
      dispatch({ type: 'CREATE_USER', data: { user: data }, ...props }),
    getUsers: props => dispatch({ type: 'GET_USERS', ...props }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Create))
}

export default HOC
