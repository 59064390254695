import React from 'react'
import { Switch, Route } from 'react-router-dom'

import List from './List'
import Create from './Create'
import Edit from './Edit'
import Show from './Show'
import './style.css'

const Routes = () => (
  <Switch>
    <Route path="/themes/create" component={Create} />
    <Route path="/themes/:id/edit" component={Edit} />
    <Route path="/themes/:theme_id/show" component={Show} />
    <Route path="/themes" component={List} />
  </Switch>
)

export default Routes
