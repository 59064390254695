import React from 'react'
import PropTypes from 'prop-types'
import { Image, Button } from 'semantic-ui-react'
import { SketchPicker, BlockPicker } from 'react-color'

const ColorPicker = ({
  color, // no need for this .
  label,
  title,
  meta: { error, touched },
  input: { onChange, value },
  handleClick,
  handleClose,
  displayColorPicker,
  ...props
}) => (
  <div>
    <div className="tuby-input__label">{title}</div>
    <div className="tuby-upload-image__wrapper">
      {/* !displayColorPicker && color && (
        <SketchPicker color={color} className="tuby-color-picker" />
      ) */}
      {displayColorPicker ? (
        <div className="tuby-popover">
          <div className="tuby-cover" onClick={handleClose} />
          <SketchPicker
            onChange={color => {
              onChange(color.rgb)
            }}
            className="tuby-color-picker"
            color={value || false}
          />
        </div>
      ) : null}
      <div>
        <Button
          icon="braille"
          onClick={handleClick}
          content=" Pick Color"
          className="tuby-button"
        />
      </div>
    </div>
    {error && touched && <div className="tuby-input--error">{error}</div>}
  </div>
)

ColorPicker.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default ColorPicker
