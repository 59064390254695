import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  class Edit extends React.Component {
    status = {
      errorMessage: '',
    }

    componentDidMount() {
      const { getChannel, getOptions, match } = this.props
      getChannel(match.params.id)
      getOptions()
    }

    handleOnClose = () => {
      const { history } = this.props
      history.goBack()
    }

    onSuccess = res => {
      const { history, getChannels } = this.props
      Toaster.success(res.data.message)
      getChannels({
        params: { limit: 12, status: 3 },
      })
      history.goBack()
    }

    onFaild = errorMessage => {
      this.setState({ errorMessage })
    }

    handleSubmit = values => {
      const { updateChannel, match } = this.props
      const data = {
        ...values,
        status: 2, // to be active .
      }
      updateChannel(data, match.params.id, {
        onFulfilled: this.onSuccess,
        onFaild: this.onFaild,
      })
    }

    render() {
      const { fetching, error, channel } = this.props

      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          fetching={fetching}
          error={error}
          onClose={this.handleOnClose}
          handleSubmit={this.handleSubmit}
          channel={channel.channel}
        />
      )
    }
  }

  Edit.propTypes = {
    t: PropTypes.func.isRequired,
    getChannels: PropTypes.func.isRequired,
    getChannel: PropTypes.func.isRequired,
    channel: PropTypes.object,
    match: PropTypes.object,
    fetching: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.func.isRequired,
    updateChannel: PropTypes.func.isRequired,
    getOptions: PropTypes.func.isRequired,
  }

  Edit.defaultProps = {
    channel: {},
    match: {},
    fetching: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    channel: api.channel || {},
    loading: api.requests.channel.put.loading,
    fetching: api.requests.channel.get.fetching,
    saving: api.requests.channel.put.fetching,
    error: api.requests.channel.put.error,
    options: api.channels_options || {},
  })

  const mapDispatchToProps = dispatch => ({
    updateChannel: (data, id, props = {}) =>
      dispatch({
        type: 'UPDATE_CHANNEL',
        data: { channel: data },
        vars: { id },
        ...props,
      }),
    getChannel: (id, props) =>
      dispatch({ type: 'GET_CHANNEL', vars: { channel_id: id }, ...props }),
    getChannels: (props = {}) => dispatch({ type: 'GET_CHANNELS', ...props }),
    getOptions: props =>
      dispatch({
        type: 'GET_CHANNELS_OPTIONS',
      }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Edit))
}

export default HOC
