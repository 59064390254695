import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router'
import { Dropdown, Icon } from 'semantic-ui-react'
import { Cards, Card } from 'atoms/Cards'
import ChannelCard from 'templates/ChannelCard'
import Status from 'atoms/Status'

const Channels = ({ channels, handleDelete, handleStatus }) => (
  <Cards gap="13px" cardWidth="300px">
    {channels.map(channel => (
      <Card key={channel.id}>
        <ChannelCard
          onClick={() => (window.location = `#/channels/${channel.id}/show`)}
          image={channel.thumbnail}
          name={channel.title || 'No Title'}
          category={channel.category && channel.category.title}
          videos={
            channel.videos_count === 0
              ? 'No Videos'
              : channel.videos_count > 1
              ? `${channel.videos_count} Videos`
              : `${channel.videos_count} Video`
          }
          status={<Status name={channel.status_label.toLowerCase()} />}
          actions={
            <Dropdown
              direction="left"
              icon={null}
              trigger={<Icon name="ellipsis horizontal" size="large" />}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="pencil alternate"
                  text="Edit"
                  as={Link}
                  to={`/channels/${channel.id}/edit`}
                />
                <Dropdown.Item
                  icon="eye"
                  text="Channel Details"
                  as={Link}
                  to={`/channels/${channel.id}/show`}
                />
                {channel.status === 2 && (
                  <Dropdown.Item
                    icon="eye slash"
                    text="Deactive"
                    onClick={() => handleStatus(channel.id, 0)}
                  />
                )}
                {channel.status !== 2 && (
                  <Dropdown.Item
                    icon="check"
                    text="Active"
                    onClick={() => handleStatus(channel.id, 2)}
                  />
                )}
                <Dropdown.Item
                  icon="trash alternate outline"
                  text="Delete"
                  onClick={() => handleDelete(channel.id)}
                />
              </Dropdown.Menu>
            </Dropdown>
          }
        />
      </Card>
    ))}
  </Cards>
)

export default Channels
