import React from 'react'
import { Header, Segment, Icon, Dimmer, Loader, Grid } from 'semantic-ui-react'
import numberFixed from '../../../../functions/numberFixed'

const width = { width: 390 }
const height = { height: 210 }
const secondWidth = { width: 260 }

const TotalCards = ({
  total_channels,
  total_videos,
  total_exclusives,
  total_features,
  total_channels_added_by_parents,
}) => (
  <Grid columns={2}>
    <Grid.Row>
      <Grid.Column>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column style={width}>
              <Segment textAlign="center" padded="very" style={height}>
                <Header as="h3">
                  Total Channels
                  <Header.Subheader style={{ marginTop: 60 }}>
                    <Icon
                      size="big"
                      color="black"
                      name="caret square right outline"
                    />
                    {total_channels && numberFixed(total_channels)}
                  </Header.Subheader>
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column style={width}>
              <Segment textAlign="center" padded="very" style={height}>
                <Header as="h3">
                  Total Videos
                  <Header.Subheader style={{ marginTop: 60 }}>
                    <Icon size="big" color="black" name="video" />
                    {total_videos && numberFixed(total_videos)}
                  </Header.Subheader>
                </Header>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column style={secondWidth}>
              <Segment textAlign="center" padded="very" style={height}>
                <Header as="h3">
                  Total Exclusive Videos
                  <Header.Subheader style={{ marginTop: 60 }}>
                    <Icon size="big" color="black" name="play circle outline" />
                    {total_exclusives && numberFixed(total_exclusives)}
                  </Header.Subheader>
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column style={secondWidth}>
              <Segment textAlign="center" padded="very" style={height}>
                <Header as="h3">
                  Total Featured videos
                  <Header.Subheader style={{ marginTop: 60 }}>
                    <Icon size="big" color="black" name="play circle" />
                    {total_features && numberFixed(total_features)}
                  </Header.Subheader>
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column style={secondWidth}>
              <Segment textAlign="center" padded="very" style={height}>
                <Header as="h3">
                  Total Channels added by parents
                  <Header.Subheader style={{ marginTop: 60 }}>
                    <Icon size="big" color="black" name="caret square right" />
                    {total_channels_added_by_parents &&
                      numberFixed(total_channels_added_by_parents)}
                  </Header.Subheader>
                </Header>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

export default TotalCards
