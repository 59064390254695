import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  class Update extends React.Component {
    componentWillMount() {
      const { getRecommendationConfig } = this.props
      getRecommendationConfig()
    }

    onSuccess = res => {
      const { getRecommendationConfig } = this.props
      Toaster.success(res.data.message)
      getRecommendationConfig()
    }

    handleSubmit = values => {
      const { updateRecommendationConfig } = this.props
      updateRecommendationConfig(values, {
        onFulfilled: this.onSuccess,
      })
    }

    onCancel = () => {
      const { history } = this.props
      history.goBack()
    }

    render() {
      const { fetching, error, recommendation_config } = this.props
      return (
        <WrappedComponent
          {...this.props}
          fetching={fetching}
          error={error}
          handleSubmit={this.handleSubmit}
          onCancel={this.onCancel}
          recommendation_config={
            recommendation_config.recommendation_ratio || {}
          }
        />
      )
    }
  }

  Update.propTypes = {
    t: PropTypes.func.isRequired,
    getRecommendationConfigOptions: PropTypes.func.isRequired,
    getRecommendationConfig: PropTypes.func.isRequired,
    updateRecommendationConfig: PropTypes.func.isRequired,
    recommendation_config: PropTypes.object,
    match: PropTypes.object,
    fetching: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.object.isRequired,
  }

  Update.defaultProps = {
    recommendation_config: {},
    match: {},
    fetching: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    recommendation_config: api.recommendation_config || {},
    loading: api.requests.recommendation_config.put.loading,
    fetching: api.requests.recommendation_config.put.fetching,
    fetching_recommendation_config:
      api.requests.recommendation_config.get.fetching,
    error: api.requests.recommendation_config.put.error,
  })

  const mapDispatchToProps = dispatch => ({
    updateRecommendationConfig: (data, props = {}) =>
      dispatch({
        type: 'UPDATE_RECOMMENDATION_CONFIG',
        data: { recommendationratio: data }, // make sure this is correct .
        ...props,
      }),

    getRecommendationConfig: props =>
      dispatch({
        type: 'GET_RECOMMENDATION_CONFIG',
        ...props,
      }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Update))
}

export default HOC
