import React from 'react'
import { connect } from 'react-redux'
import { reduxForm as _reduxForm, getFormValues } from 'redux-form'

const reduxForm = config => WrappedComponent => {
  const required = value => (value ? undefined : 'Required')
  const email = value =>
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined
  const passwordsMatch = (value, allValues) =>
    value !== allValues.password ? "Passwords don't match" : undefined
  const maxLength = max => value =>
    value && value.length > max
      ? `Must be ${max} characters or less`
      : undefined

  const minLength = min => value =>
    value && value < min ? `Must be at least ${min}` : undefined

  const IPAddress = value =>
    !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      value,
    )
      ? 'Invalid IP address'
      : undefined

  const isNegative = value =>
    value && value < 0 ? `Must be positive number` : undefined
  const isfloat = value =>
    value && !isNaN(value) && value.toString().indexOf('.') != -1
      ? `The value must be Integer`
      : undefined
  const validate = {
    required,
    email,
    passwordsMatch,
    maxLength,
    IPAddress,
    minLength,
    isNegative,
    isfloat,
  }

  const Component = ({ formValues, ...props }) => (
    <WrappedComponent formValues={formValues} validate={validate} {...props} />
  )

  const mapStateToProps = state => ({
    formValues: getFormValues(config.form)(state),
  })

  return connect(mapStateToProps)(_reduxForm(config)(Component))
}

export default reduxForm
