import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RichTextEditor from 'react-rte'

class EditorField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: null,
    }
  }

  handleOnChange = value => {
    const {
      input: { onChange },
    } = this.props
    this.setState({ value })
    onChange(value.toString('html'))
  }

  handelValue = value => {
    if (value)
      return RichTextEditor.createValueFromString(
        this.props.input.value,
        'html',
      )
    return RichTextEditor.createEmptyValue()
  }

  render() {
    const {
      title,
      meta: { touched, error },
      ...props
    } = this.props
    const value = this.state.value || this.handelValue(this.props.input.value)
    return (
      <div>
        <div className="tuby-editor__label">{title}</div>
        <RichTextEditor
          {...props}
          value={value}
          onChange={this.handleOnChange}
        />
        {error && touched && <div className="tuby-editor--error">{error}</div>}
      </div>
    )
  }
}

EditorField.propTypes = {
  title: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
}

export default EditorField
