import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'

const HOC = WrappedComponent => {
  class Show extends React.Component {
    componentWillMount() {
      const { getUser, match } = this.props
      getUser(match.params.id)
    }

    handleOnClose = () => {
      const { history } = this.props
      history.goBack()
    }

    render() {
      const { user } = this.props
      return (
        <WrappedComponent
          onClose={this.handleOnClose}
          {...this.props}
          user={user.user}
        />
      )
    }
  }

  Show.propTypes = {
    t: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    user: PropTypes.object,
    match: PropTypes.isRequired,
    fetching: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.func.isRequired,
  }

  Show.defaultProps = {
    user: {},
    fetching: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    user: api.user || {},
    loading: api.requests.user.get.loading,
    fetching: api.requests.user.get.fetching,
    error: api.requests.user.get.error,
  })

  const mapDispatchToProps = dispatch => ({
    getUser: (id, props) =>
      dispatch({ type: 'GET_USER', vars: { id }, ...props }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Show))
}

export default HOC
