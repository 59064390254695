import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Button, Grid, Message, Dimmer, Loader } from 'semantic-ui-react'
import reduxForm from 'atoms/reduxForm'
import textInput from 'molecules/reduxFormFields/textInput'

const Form = ({
  handleSubmit,
  onCancel,
  validate,
  t,
  fetching,
  errorMessage,
  isCreate,
  formValues,
  ...props
}) => {
  const trending_videos =
    formValues && formValues.trends_ratio && formValues.trends_ratio
  const new_videos =
    formValues && formValues.news_ratio && formValues.news_ratio
  const videos_from_channels =
    formValues &&
    formValues.videos_by_channels_ratio &&
    formValues.videos_by_channels_ratio
  const videos_from_categories =
    formValues &&
    formValues.videos_by_categories_ratio &&
    formValues.videos_by_categories_ratio
  const sum =
    parseInt(trending_videos) +
    parseInt(new_videos) +
    parseInt(videos_from_channels) +
    parseInt(videos_from_categories)

  const isNone = isNaN(sum)

  return (
    <React.Fragment>
      {sum !== 100 && !isNone && (
        <Message negative className="recommendation_ratio-form--error-message">
          {'The sum of all fields must be equal 100% .'}
        </Message>
      )}

      {errorMessage && (
        <Message negative className="recommendation_ratio-form--error-message">
          {errorMessage}
        </Message>
      )}
      <Grid doubling columns={2}>
        {fetching && (
          <Dimmer inverted active>
            <Loader size="medium">Processing...</Loader>
          </Dimmer>
        )}
        <Grid.Row>
          <Grid.Column width={4}>
            <Field
              validate={[
                validate.required,
                validate.isNegative,
                validate.isfloat,
              ]}
              name="trends_ratio"
              title={t('Trending Videos')}
              component={textInput}
              type="number"
              icon="percent"
              iconPosition="right"
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Field
              validate={[
                validate.required,
                validate.isNegative,
                validate.isfloat,
              ]}
              name="news_ratio"
              title={t('New Videos')}
              component={textInput}
              type="number"
              icon="percent"
              iconPosition="right"
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <Field
              validate={[
                validate.required,
                validate.isNegative,
                validate.isfloat,
              ]}
              name="videos_by_channels_ratio"
              title={t('Videos From Channels')}
              component={textInput}
              type="number"
              icon="percent"
              iconPosition="right"
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Field
              validate={[
                validate.required,
                validate.isNegative,
                validate.isfloat,
              ]}
              name="videos_by_categories_ratio"
              title={t('Videos From Categories')}
              component={textInput}
              type="number"
              icon="percent"
              iconPosition="right"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16} className="admin-form_button">
            <Button
              primary
              disabled={fetching || sum !== 100 || isNone}
              onClick={handleSubmit}
            >
              {t('Save')}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  )
}
Form.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  validate: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  isCreate: PropTypes.bool,
  errorMessage: PropTypes.string,
  options: PropTypes.object,
  formValues: PropTypes.object,
}
Form.defaultProps = {
  fetching: false,
  isCreate: false,
  errorMessage: null,
  options: {},
  formValues: {},
}
export default reduxForm({
  form: 'recommendation_ratios_form',
  enableReinitialize: true,
})(Form)
