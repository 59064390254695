import React from 'react'
import { Grid } from 'semantic-ui-react'
import Search from 'molecules/Search'
import Dropdown from 'molecules/semantic-ui/Dropdown'

const stateOptions = [
  { key: 'all', value: 'all', text: 'All Videos' },
  { key: '1', value: '1', text: 'Active' },
  { key: '2', value: '2', text: 'In Active' },
]
const Filters = ({ disabled, handleFilters, options }) => (
  <React.Fragment>
    <Grid.Column width={4} computer={4} mobile={16}>
      <Search
        disabled={disabled}
        fluid
        size="small"
        icon="search"
        iconPosition="left"
        placeholder="Search..."
        onTyping={value => handleFilters('search', value)}
      />
    </Grid.Column>
    <Grid.Column
      width={10}
      centered
      verticalAlign="middle"
      computer={10}
      mobile={16}
    >
      <div
        style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}
      >
        <span style={{ marginRight: 10 }}>Filter By</span>
        <Dropdown
          selection
          clearable
          disabled={disabled}
          placeholder="Status"
          labelKey="title"
          valueKey="id"
          options={options.statuses}
          onChange={(e, data) => handleFilters('status', data.value)}
        />
      </div>
    </Grid.Column>
  </React.Fragment>
)

export default Filters
