import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './style.css'

class Pagination extends Component {
  handleOnChange = page => {
    this.props.onChange(page)
  }

  pagesNumber = () => {
    const { limit, total, showing, page } = this.props

    if (!total || total <= 0) return false

    const pages = Math.ceil(total / showing)

    if (pages <= 1) return false

    const average = Math.ceil(limit / 2)

    const results = []

    let i = 1 + Math.ceil(Math.max(0, page - average))
    let lastNo = i + (limit - 1)

    if (lastNo >= pages) {
      i = 1 + Math.max(0, pages - limit)
      lastNo = pages
    }

    results.push(
      <button
        key={`p-btn-${Math.floor(Math.random() * 10000000)}`}
        className="bg"
        onClick={() => this.handleOnChange(1)}
        disabled={page <= 1 ? true : false}
      >
        <span className="double left">
          <span className="arrow left" />
          <span className="arrow left" />
        </span>
      </button>,
    )

    results.push(
      <button
        key={`p-btn-${Math.floor(Math.random() * 10000000)}`}
        className="bg"
        disabled={page <= 1 ? true : false}
        onClick={() => this.handleOnChange(page - 1 || 1)}
      >
        <span className="arrow left" />
      </button>,
    )

    for (; i <= lastNo; i++) {
      const active = i === page ? true : false
      const n = i
      results.push(
        <button
          key={`pagination-${Math.floor(Math.random() * 10000000)}`}
          className={active && 'active'}
          onClick={() => this.handleOnChange(n)}
        >
          <span>{i}</span>
        </button>,
      )
    }

    if (pages > lastNo) {
      results.push(
        <button
          className="dotted"
          key={`p-btn-${Math.floor(Math.random() * 10000000)}`}
        >
          <span>...</span>
        </button>,
      )
    }

    results.push(
      <button
        key={`p-btn-${Math.floor(Math.random() * 10000000)}`}
        className="bg"
        disabled={page >= pages ? true : false}
        onClick={() => this.handleOnChange(page + 1)}
      >
        <span className="arrow right" />
      </button>,
    )

    results.push(
      <button
        key={`p-btn-${Math.floor(Math.random() * 10000000)}`}
        className="bg"
        disabled={page < pages ? false : true}
        onClick={() => this.handleOnChange(pages)}
      >
        <span className="double right">
          <span className="arrow right" />
          <span className="arrow right" />
        </span>
      </button>,
    )

    return results
  }
  render() {
    return <div className="bricks-pagination">{this.pagesNumber()}</div>
  }
}

Pagination.propTypes = {
  limit: PropTypes.number,
  total: PropTypes.number,
  showing: PropTypes.number,
  page: PropTypes.number,
  onChange: PropTypes.func,
}

Pagination.defaultProps = {
  limit: 5,
  total: 0,
  showing: 0,
  page: 0,
  onChange: () => {},
}

export default Pagination
