import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader, Container } from 'semantic-ui-react'
import HOC from './HOC'

const VideoPlayer = ({ match }) => (
  <Container
    text
    style={{
      margin: '50px 0',
      direction: match.params.locale === 'ar' && 'rtl',
    }}
    textAlign={match.params.locale === 'ar' && 'right'}
  >
    {!match.params.video_id ? (
      <Dimmer inverted active>
        <Loader size="medium">LOADING...</Loader>
      </Dimmer>
    ) : (
      <iframe
        width="100%"
        height="500"
        src={`https://www.youtube.com/embed/${match.params.video_id}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; picture-in-picture"
        allowFullScreen
      />
    )}
  </Container>
)

VideoPlayer.propTypes = {
  match: PropTypes.object,
}

VideoPlayer.defaultProps = {
  match: {},
}

export default HOC(VideoPlayer)
