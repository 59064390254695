import React from 'react'
import { Switch, Route } from 'react-router-dom'

import List from './List'
import Create from './Create'

const Routes = () => (
  <Switch>
    <Route path="/notifications/create" component={Create} />
    <Route path="/notifications" component={List} />
  </Switch>
)

export default Routes
