import React from 'react'
import PropTypes from 'prop-types'
import { Header, Icon } from 'semantic-ui-react'

const VideoCard = ({
  channel,
  image,
  title,
  date,
  views,
  status,
  handleModal,
  actions,
}) => (
  <div className="video-card" onClick={handleModal}>
    {actions && <div className="video-card__actions">{actions}</div>}
    <div className="video-card__image">
      <img alt={title} className="video-card__img" src={image} />
      <div className="video-card__playbtn">
        <Icon name="chevron circle right" size="huge" />
      </div>
    </div>
    <div className="video-card__details">
      <Header className="video-card__title" as="h4">
        {title}
      </Header>
      {channel && (
        <div className="video-card__details--item video-card__details--channel-name">
          <b>{channel}</b>
        </div>
      )}
      {date && <div className="video-card__details--item">{date}</div>}
      {views && <div className="video-card__details--item">{views}</div>}
      {status && <div className="video-card__details--item">{status}</div>}
    </div>
  </div>
)

VideoCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  views: PropTypes.string,
  status: PropTypes.any,
}

VideoCard.defaultProps = {
  image: '',
  title: null,
  date: null,
  views: null,
  status: null,
}
export default VideoCard
