import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Button, Grid, Message } from 'semantic-ui-react'
import reduxForm from 'atoms/reduxForm'
import textInput from 'molecules/reduxFormFields/textInput'

const Form = ({
  handleSubmit,
  validate,
  t,
  fetching,
  errorMessage,
  isCreate,
}) => (
  <Grid columns={1}>
    {errorMessage && (
      <Grid.Row className="admin-form_error-message">
        <Message negative className="admin-form_error-message">
          {errorMessage}
        </Message>
      </Grid.Row>
    )}
    <Grid.Row>
      <Grid.Column className="admin-form_field">
        <Field
          validate={[validate.required]}
          name="first_name"
          title={t('First Name')}
          component={textInput}
        />
      </Grid.Column>
      <Grid.Column className="admin-form_field">
        <Field
          validate={[validate.required]}
          name="last_name"
          title={t('Last Name')}
          component={textInput}
        />
      </Grid.Column>
      <Grid.Column className="admin-form_field">
        <Field
          validate={[validate.required]}
          name="username"
          title={t('Username')}
          disabled={!isCreate}
          component={textInput}
        />
      </Grid.Column>
      <Grid.Column className="admin-form_field">
        <Field
          validate={[validate.required, validate.email]}
          name="email"
          title={t('Email')}
          component={textInput}
        />
      </Grid.Column>
      <Grid.Column className="admin-form_field">
        <Field
          validate={isCreate && [validate.required]}
          name="password"
          type="password"
          title={t('Password')}
          component={textInput}
        />
      </Grid.Column>
      <Grid.Column className="admin-form_field">
        <Field
          validate={[validate.required]}
          type="number"
          name="mobile"
          title={t('Phone Number')}
          component={textInput}
        />
      </Grid.Column>
      <Grid.Column className="admin-form_button">
        <Button primary disabled={fetching} onClick={handleSubmit}>
          {t('Save')}
        </Button>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

Form.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  validate: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  isCreate: PropTypes.bool,
}
Form.defaultProps = {
  fetching: false,
  errorMessage: null,
  isCreate: false,
}
export default reduxForm({
  form: 'users_form',
  enableReinitialize: true,
})(Form)
