import React from 'react'
import { Link } from 'react-router-dom'
import {
  Image,
  Message,
  Grid,
  Header,
  Dimmer,
  Loader,
  Button,
  Divider,
} from 'semantic-ui-react'
import Pagination from 'molecules/Pagination'
import AppContainer from 'templates/AppContainer'
import Filters from './Filters'
import Channels from './Channels'

const Container = ({
  channels,
  noChannels,
  fetching,
  channelLoading,
  handleFilters,
  options,
  pagination,
  filters,
  handleDelete,
  channel_options,
  handleStatus,
}) => (
  <AppContainer
    header="Channels"
    button={
      <Button
        icon="plus"
        color="violet"
        content="New Channel"
        primary
        as={Link}
        to={`/channels/create`}
      />
    }
  >
    <Grid divided="vertically">
      <Grid.Row columns={2}>
        <Filters
          handleFilters={handleFilters}
          options={options}
          channel_options={channel_options}
          filters={filters}
        />
      </Grid.Row>
    </Grid>
    <Divider />
    {noChannels && <Message warning header="No channels founded!" />}
    <div style={{ position: 'relative' }}>
      {!channelLoading && fetching && (
        <Dimmer inverted active={true}>
          <Loader size="medium" />
        </Dimmer>
      )}

      <Channels
        channels={channels}
        handleDelete={handleDelete}
        handleStatus={handleStatus}
      />

      {pagination.total_entries > filters.limit && (
        <div style={{ padding: '30px 0' }}>
          <Pagination
            total={pagination.total_entries}
            page={filters.page}
            showing={filters.limit}
            onChange={page => handleFilters('page', page)}
          />
        </div>
      )}
    </div>
  </AppContainer>
)

export default Container
