import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import moment from 'moment'

const HOC = WrappedComponent => {
  class Notifications extends Component {
    state = {
      filters: {
        page: 1,
        limit: 20,
      },
    }

    handleFilters = (key, value) => {
      const { filters } = this.state
      if (typeof key === 'object') {
        key.map((k, i) => {
          filters[k] = value[i]
        })
      } else {
        filters[key] = value
      }
      filters.page = key === 'page' ? value : 1
      this.setState({ filters })
      this.loadNotifications(filters)
    }

    loadNotifications = (filters = this.state.filters) => {
      this.props.getNotifications({ params: filters })
    }

    componentDidMount() {
      this.loadNotifications()
    }

    render() {
      const { t } = this.props
      const headers = [
        {
          name: t('Date'),
          render: row => moment(row.pushed_at).format('DD MMM YYYY hh:mm A'),
        },
        { name: t('Title'), key: 'title' },
        { name: t(`Message ${t('English')}`), key: 'message_en' },
        { name: t(`Message ${t('Arabic')}`), key: 'message_ar' },
      ]
      return (
        <WrappedComponent
          {...this.props}
          notifications={this.props.notifications.notifications || []}
          pagination={this.props.notifications.pagination || {}}
          filters={this.state.filters}
          handleFilters={this.handleFilters}
          headers={headers}
        />
      )
    }
  }

  const mapStateToProps = ({ api }) => ({
    notifications: api.notifications || {},
    fetching: api.requests.notifications.get.fetching,
    error: api.requests.notifications.get.error,
  })

  const mapDispatchToProps = dispatch => ({
    getNotifications: props =>
      dispatch({
        type: 'GET_NOTIFICATIONS',
        ...props,
      }),
  })
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(translate('translations')(Notifications))
}

export default HOC
