import React from 'react'
import PropTypes from 'prop-types'
import { Button, Pagination, Checkbox, Segment } from 'semantic-ui-react'
import { Link, Route } from 'react-router-dom'
import Table from '../../../atoms/Table'
import AppContainer from '../../../templates/AppContainer'

import Create from '../Create'
import Update from '../Update'
import Show from '../Show'
import Delete from '../Delete'
import './style.css'

const IPWhitelists = ({
  headers,
  data,
  pagination,
  activePage,
  handlePaginationChange,
  loading,
  error,
  fetching,
  t,
  handleChange,
  ipVhitelistValue,
  error_ip_whitelist_setting,
}) => (
  <AppContainer
    header={t('IP Whitelists')}
    button={
      <>
        <Button
          icon="plus"
          color="violet"
          content="New IP Whitelist"
          primary
          as={Link}
          to="ip-whitelists/create"
        />

        <Segment
          style={{ display: 'inline', padding: '9px', background: ' #c73f76' }}
          title={
            ipVhitelistValue === 't'
              ? 'Active IP Whitelist'
              : 'Deactive IP Whitelist'
          }
        >
          <Checkbox
            style={{ minHeight: ' 13px' }}
            slider
            onChange={(event, data) => handleChange(data.checked)}
            checked={ipVhitelistValue === 't' ? true : false}
            disabled={error_ip_whitelist_setting}
          />
        </Segment>
      </>
    }
  >
    <Table headers={headers} data={data} loading={fetching} error={error} />
    <Route path="/ip-whitelists/create" component={Create} />
    <Route path="/ip-whitelists/:id/edit" component={Update} />
    <Route path="/ip-whitelists/:id/show" component={Show} />
    <Route path="/ip-whitelists/:id/delete" component={Delete} />
    {pagination.total_pages > 1 && (
      <Pagination
        activePage={activePage}
        onPageChange={handlePaginationChange}
        size="mini"
        totalPages={pagination.total_pages}
        ellipsisItem={null}
      />
    )}
  </AppContainer>
)

IPWhitelists.propTypes = {
  t: PropTypes.func.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string,
      render: PropTypes.func,
    }),
  ).isRequired,
  data: PropTypes.array,
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  activePage: PropTypes.number,
  handlePaginationChange: PropTypes.func.isRequired,
}

IPWhitelists.defaultProps = {
  data: [],
  pagination: {},
  loading: false,
  error: null,
  activePage: null,
}

export default IPWhitelists
