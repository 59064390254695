import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'semantic-ui-react'
import FileBase64 from 'react-file-base64'
import Upload from 'atoms/UploadFiles'
import moment from 'moment'

const InputImage = ({
  image,
  label,
  title,
  meta: { error, touched },
  input: { onChange, value },
  ...props
}) => (
  <div>
    <div className="tuby-input__label">{title}</div>
    {(value || image) && (
      <div className="tuby-upload-image__wrapper">
        <Image
          {...props}
          // className="tuby-upload-image__image"
          //  src={image}
          src={value.data ? value.data : image}
          size="medium"
          error={!!(touched && error)}
        />
      </div>
    )}
    <Upload
      accept="image/*"
      onDone={files =>
        onChange({
          data: files.file,
          // filename: files.name,
          filename: moment(new Date()).format('MMDDYYYYHHmmss'),
          content_type: files.type,
        })
      }
    />
    {error && touched && <div className="tuby-input--error">{error}</div>}
  </div>
)

InputImage.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default InputImage

/*
  base64
  {
      data: files.base64,
      filename: files.name,
      content_type: files.type,
  }

  Upload
  {
      data: files.file,
      filename: files.name,
      content_type: files.type,
      // size
  }

*/
