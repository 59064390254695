import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Button, Grid, Message, Dimmer, Loader } from 'semantic-ui-react'
import reduxForm from 'atoms/reduxForm'
import textInput from 'molecules/reduxFormFields/textInput'
import dropdownInput from 'molecules/reduxFormFields/dropdownInput'
import asyncDropdownInput from 'molecules/reduxFormFields/asyncDropdownInput'

const Form = ({
  handleSubmit,
  onChannesListChange,
  onCancel,
  validate,
  t,
  options,
  channels,
  fetching,
  fetch_channels,
  errorMessage,
  isCreate,
}) => {
  const statuses =
    options.statuses &&
    options.statuses.map(status => ({
      text: status.title,
      value: status.id.toString(),
    }))

  const channelsOptions =
    channels &&
    channels.map(channel => ({
      text: channel.title,
      value: channel.id,
    }))

  const video_types =
    options.video_types &&
    options.video_types.map(type => ({ text: type.title, value: type.id }))

  return (
    <React.Fragment>
      {errorMessage && (
        <Message negative className="video-form--error-message">
          {errorMessage}
        </Message>
      )}
      <Grid doubling columns={2}>
        {fetching && (
          <Dimmer inverted active>
            <Loader size="medium">Processing...</Loader>
          </Dimmer>
        )}
        <Grid.Row>
          <Grid.Column width={4} className="video-form_field">
            <Field
              validate={[validate.required]}
              name="youtube_id"
              title={t('Youtube ID')}
              component={textInput}
            />
          </Grid.Column>

          <Grid.Column width={4} className="video-form_field">
            <Field
              validate={[validate.required]}
              name="channel_id"
              title={t('Channel')}
              placeholder="Type to search"
              options={channelsOptions}
              onSearch={onChannesListChange}
              loading={fetch_channels}
              search
              clearable
              component={asyncDropdownInput}
            />
          </Grid.Column>

          <Grid.Column width={4} className="video-form_field">
            <Field
              name="video_type"
              title={t('Video Type')}
              options={video_types}
              component={dropdownInput}
              clearable
            />
          </Grid.Column>

          <Grid.Column width={4} className="video-form_field">
            <Field
              name="status"
              title={t('Status')}
              validate={[validate.required]}
              options={statuses}
              component={dropdownInput}
              clearable
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="video-form_button">
          <Grid.Column width={16}>
            <Button primary disabled={fetching} onClick={handleSubmit}>
              {t('Save')}
            </Button>
            <Button color="grey" onClick={onCancel}>
              {t('Cancel')}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  )
}

Form.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  validate: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  fetch_channels: PropTypes.bool,
  isCreate: PropTypes.bool,
  errorMessage: PropTypes.string,
  options: PropTypes.object,
  channels: PropTypes.array,
}
Form.defaultProps = {
  fetching: false,
  fetch_channels: false,
  isCreate: false,
  errorMessage: null,
  options: {},
  channels: [],
}
export default reduxForm({
  form: 'videos_form',
  enableReinitialize: true,
})(Form)
