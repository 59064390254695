import React from 'react'
import PropTypes from 'prop-types'
import { TextArea as SemTextArea } from 'semantic-ui-react'

const TextArea = ({ title, input, meta: { error, touched }, ...props }) => (
  <div>
    <div className="tuby-input__label">{title}</div>
    <SemTextArea
      fluid
      onChange={(e, data) => input.onChange(data.value)}
      {...props}
      error={!!(touched && error)}
    />
    {error && touched && <div className="tuby-input--error">{error}</div>}
  </div>
)

TextArea.propTypes = {
  title: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
}

export default TextArea
