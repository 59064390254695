import React from 'react'
import { Switch, Route } from 'react-router-dom'

import RecommendationRatio from './Edit'
import './style.css'

const Routes = () => (
  <Switch>
    <Route path="/recommendation-config" component={RecommendationRatio} />
  </Switch>
)

export default Routes
