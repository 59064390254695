import React from 'react'
import PropTypes from 'prop-types'
import Header from 'organisms/Header'
import Sidebar from 'organisms/Sidebar'
import { menuOptions } from 'pages/Data/Options'

class Wrapper extends React.Component {
  state = {
    visible: false,
  }

  componentDidMount() {
    const sidebar = document.querySelector(
      '.wrapper .content-wrapper .sidebar-wrapper',
    )
    sidebar.addEventListener(
      'click',
      event => {
        if (sidebar !== event.target) return
        this.toggleSidebar()
      },
      false,
    )
  }

  toggleSidebar = () => {
    const { visible } = this.state
    this.setState({ visible: !visible })
  }

  render() {
    const { visible } = this.state
    const { children } = this.props
    return (
      <div className="wrapper">
        <div className="header-wrapper">
          <Header toggleSidebar={this.toggleSidebar} />
        </div>
        <div className="content-wrapper">
          <div
            className={`sidebar-wrapper ${visible &&
              'sidebar-wrapper--active-mobile'}`}
          >
            <div className="sidebar-cover">
              <Sidebar
                toggleSidebar={this.toggleSidebar}
                options={menuOptions}
              />
            </div>
          </div>
          <div className="pages-wrapper">{children}</div>
        </div>
      </div>
    )
  }
}

export default Wrapper

Wrapper.propTypes = {
  children: PropTypes.any.isRequired,
}
