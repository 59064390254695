import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { Icon } from 'semantic-ui-react'
import Menu from 'organisms/Menu'
import auth from '../../../functions/auth'

import stc from '../../../asset/images/stc2.png'

const Sidebar = ({ toggleSidebar, options }) => (
  <div className="tuby-sidebar">
    <div className="tuby-sidebar_menu">
      <Menu toggleSidebar={toggleSidebar} options={options} />
    </div>
    <div className="tuby-sidebar_logout">
      <img width="100" src={stc} alt="Tuby Admin" />
    </div>
  </div>
)

Sidebar.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
}

export default translate('translations')(Sidebar)
