/* eslint no-nested-ternary: off */
import React from 'react'
import PropTypes from 'prop-types'
import { Table as SemTable, Dimmer, Loader } from 'semantic-ui-react'
import TableData from './TableData'

/**
 * This table will take two main props
 * as @argument {headers, data}
 * header is the header of the table which contains thess
 * keys {
 *   name: 'is the name of column',
 *   key: is the record key,
 *   render: is a function its @argument { record }
 *     for example render: (record) => <a href={record.id}/>
 *   if render key present? no need to add key at header
 *   props: is the common props like className ...etc {}
 *   tdProps: props for table data
 * }
 */

const Table = ({ headers, data, loading, error }) => (
  <SemTable className="position-relative" unstackable>
    <SemTable.Header>
      <SemTable.Row>
        {headers.map((head, index) => (
          <SemTable.HeaderCell key={index} {...head.props}>
            {head.name}
          </SemTable.HeaderCell>
        ))}
      </SemTable.Row>
    </SemTable.Header>
    {loading ? (
      <React.Fragment>
        <SemTable.Body>
          <SemTable.Row>
            <SemTable.Cell
              colSpan={headers.length}
              textAlign="center"
              style={{ height: 45 }}
            >
              <Dimmer inverted active={loading}>
                <Loader size="medium">Loading</Loader>
              </Dimmer>
            </SemTable.Cell>
          </SemTable.Row>
        </SemTable.Body>
      </React.Fragment>
    ) : data && data.length > 0 ? (
      <TableData data={data} headers={headers} />
    ) : (
      <SemTable.Body>
        <SemTable.Row>
          <SemTable.Cell colSpan={headers.length} textAlign="center">
            {error || 'No data'}
          </SemTable.Cell>
        </SemTable.Row>
      </SemTable.Body>
    )}
  </SemTable>
)

Table.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string,
      render: PropTypes.func,
    }),
  ).isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.string,
}

Table.defaultProps = {
  data: [],
  loading: false,
  error: null,
}

export default Table
