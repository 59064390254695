import React from 'react'
import PropTypes from 'prop-types'
import FileBase64 from 'react-file-base64'
import Upload from 'atoms/UploadFiles'
import moment from 'moment'

const VideoFileInput = ({
  video,
  label,
  title,
  meta: { error, touched },
  input: { onChange, value },
  ...props
}) => (
  <div>
    <div className="tuby-input__label">{title}</div>
    {(value || video) && (
      <div className="tuby-upload-image__wrapper">
        <video width="365" height="300px" controls>
          <source
            alt={title}
            src={value.data ? value.data : video}
            type="video/mp4"
          />
        </video>
      </div>
    )}
    <Upload
      accept="video/mp4,video/x-m4v,video/*"
      onDone={files =>
        onChange({
          data: files.file,
          // filename: files.name,
          filename: moment(new Date()).format('MMDDYYYYHHmmss'),
          content_type: files.type,
        })
      }
    />
    {error && touched && <div className="tuby-input--error">{error}</div>}
  </div>
)

VideoFileInput.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default VideoFileInput
