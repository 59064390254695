import React from 'react'
import { Input } from 'semantic-ui-react'

const Search = ({ handleOnChange, ...props }) => (
  <Input
    fluid
    size="small"
    icon="search"
    iconPosition="left"
    placeholder="Search..."
    onChange={handleOnChange}
    {...props}
  />
)

export default Search
