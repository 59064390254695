import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader } from 'semantic-ui-react'
import Modal from '../../../atoms/Modal'
import Form from './Form'

const Update = ({
  channel,
  onClose,
  handleSubmit,
  error,
  fetching,
  saving,
  options,
  errorMessage,
  t,
}) => (
  <Modal
    size="mini"
    open
    close={onClose}
    headerIcon="caret square up outline"
    headerContent="Add Channel To Tuby"
  >
    {fetching && (
      <Dimmer inverted active>
        <Loader size="medium">Fetching channel data...</Loader>
      </Dimmer>
    )}
    <Form
      t={t}
      onSubmit={handleSubmit}
      // errorMessage={error}
      errorMessage={errorMessage}
      fetching={saving}
      options={options}
      initialValues={channel}
    />
  </Modal>
)

export default Update

Update.propTypes = {
  t: PropTypes.func.isRequired,
  channel: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  error: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  saving: PropTypes.bool,
}

Update.defaultProps = {
  channel: {},
  fetching: false,
  error: null,
  saving: false,
}
