import React, { Component } from 'react'
import { connect } from 'react-redux'

const HOC = WrappedComponent => {
  class Dashboard extends Component {
    state = {
      filters: {
        last_week: null,
        all_time: null,
      },
      activeModal: false,
      videoId: '',
    }

    handleCloseModal = (videoId = '') => {
      this.setState({ activeModal: !this.state.activeModal, videoId })
    }

    handleFilters = (key, value) => {
      const { filters } = this.state
      filters[key] = value
      this.setState({ filters })
      this.loadDashboard(filters)
    }

    loadDashboard = (filters = this.state.filters) => {
      const { getDashboard } = this.props
      getDashboard({ params: filters })
    }

    componentDidMount() {
      this.loadDashboard()
    }

    render() {
      const dashboard = this.props.dashboard || {}
      return (
        <WrappedComponent
          {...this.state}
          {...this.props}
          dashboard={dashboard}
          filters={this.state.filters}
          noDashboard={!this.props.fetching && dashboard.length <= 0}
          handleFilters={this.handleFilters}
          handleCloseModal={this.handleCloseModal}
        />
      )
    }
  }

  const mapStateToProps = ({ api }) => ({
    dashboard: api.dashboard || {},
    fetching: api.requests.dashboard.get.fetching,
    loading: api.requests.dashboard.get.loading,
    error: api.requests.dashboard.get.error,
  })

  const mapDispatchToProps = dispatch => ({
    getDashboard: props =>
      dispatch({
        type: 'GET_DASHBOARD',
        ...props,
      }),
  })
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Dashboard)
}

export default HOC
