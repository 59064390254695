import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Button, Divider } from 'semantic-ui-react'
import Pagination from 'molecules/Pagination'
import AppContainer from 'templates/AppContainer'
import Filters from './Filters'
import Table from '../../../atoms/Table'

const Container = ({
  notifications,
  pagination,
  fetching,
  error,
  handleFilters,
  filters,
  headers,
}) => (
  <AppContainer
    header="Notifications"
    button={
      <Button
        icon="plus"
        color="violet"
        content="New Notification"
        primary
        as={Link}
        to={`/notifications/create`}
      />
    }
  >
    <Grid divided="vertically">
      <Filters handleFilters={handleFilters} filters={filters} />
    </Grid>
    <Divider />
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Table
            headers={headers}
            data={notifications}
            loading={fetching}
            error={error}
            stackable
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    {!fetching && pagination.total_entries > filters.limit && (
      <div style={{ padding: '30px 0' }}>
        <Pagination
          total={pagination.total_entries}
          page={filters.page}
          showing={filters.limit}
          onChange={page => handleFilters('page', page)}
        />
      </div>
    )}
  </AppContainer>
)

export default Container
