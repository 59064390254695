import React from 'react'
import PropTypes from 'prop-types'
import { Image, Header } from 'semantic-ui-react'
import Status from 'atoms/Status'
import './style.css'

const defaultImage = 'https://react.semantic-ui.com/images/wireframe/image.png'

const ChannelInfo = ({ channel }) => (
  <div className="channel-info">
    <div className="channel-info_thumnail">
      <Image
        src={channel.thumbnail || defaultImage}
        size="small"
        verticalAlign="top"
      />
    </div>
    <div className="channel-info__detils">
      <Header as="h3">{channel.title || '...'}</Header>
      <div className="channel-info__row">
        {channel.category && channel.category.title}
      </div>
      <div className="channel-info__row">
        {channel.age_groups && channel.age_groups.length <= 0 && 'No age group'}
        {channel.age_groups && channel.age_groups.map(g => g.title).join(', ')}
        {` | ${
          channel.videos_counts === 0
            ? 'No Videos'
            : channel.videos_counts > 1
            ? `${channel.videos_counts} Videos`
            : `${channel.videos_counts} Video`
        }`}
      </div>
      <div className="channel-info__row">
        <Status
          name={channel.status_label && channel.status_label.toLowerCase()}
        />
      </div>
      <div className="channel-info__row">{channel.description}</div>
    </div>
  </div>
)

export default ChannelInfo
