import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader } from 'semantic-ui-react'
import Modal from '../../../atoms/Modal'
import DeleteBtn from './DeleteBtn'

const Update = ({
  ip_whitelist,
  onClose,
  handleSubmit,
  error,
  fetching,
  deleting,
  t,
}) => (
  <Modal
    size="tiny"
    open
    close={onClose}
    headerIcon="unlock alternate"
    headerContent="Delete IP Whitelist"
  >
    {fetching && (
      <Dimmer inverted active>
        <Loader size="medium">Fetching IP Whitelist data...</Loader>
      </Dimmer>
    )}
    <DeleteBtn
      t={t}
      handleSubmit={handleSubmit}
      errorMessage={error}
      fetching={deleting}
      ip_whitelist={ip_whitelist}
    />
  </Modal>
)

export default Update

Update.propTypes = {
  t: PropTypes.func.isRequired,
  ip_whitelist: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  deleting: PropTypes.bool,
  error: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

Update.defaultProps = {
  ip_whitelist: {},
  fetching: false,
  error: null,
  deleting: false,
}
