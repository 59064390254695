import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Button, Grid, Message, Dimmer, Loader } from 'semantic-ui-react'
import reduxForm from 'atoms/reduxForm'
import textInput from 'molecules/reduxFormFields/textInput'
import dropdownInput from 'molecules/reduxFormFields/dropdownInput'
import InputImage from 'molecules/reduxFormFields/InputImage'
import colorPicker from 'molecules/reduxFormFields/colorPicker'

const statuses = [{ text: 'Active', value: 2 }, { text: 'InActive', value: 1 }]

const Form = ({
  handleSubmit,
  onCancel,
  validate,
  t,
  fetching,
  errorMessage,
  isCreate,
  ...props
}) => (
  <React.Fragment>
    {errorMessage && (
      <Message negative className="theme-form--error-message">
        {errorMessage}
      </Message>
    )}
    <Grid doubling columns={2}>
      {fetching && (
        <Dimmer inverted active>
          <Loader size="medium">Processing...</Loader>
        </Dimmer>
      )}
      <Grid.Row>
        <Grid.Column width={4} className="theme-form_field">
          <Field
            validate={[validate.required]}
            name="title_en"
            title={t('English Title')}
            component={textInput}
          />
        </Grid.Column>
        <Grid.Column width={8} className="theme-form_field">
          <Field
            validate={[validate.required]}
            name="title_ar"
            title={t('Arabic Title')}
            component={textInput}
          />
        </Grid.Column>

        <Grid.Column width={4} className="channel-form_field">
          <Field
            name="status"
            title={t('Status')}
            options={statuses}
            component={dropdownInput}
            clearable
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4} className="theme-form_field">
          <Field
            component={InputImage}
            name="recommended"
            title={t('Recommended')}
            validate={isCreate && [validate.required]}
            image={!isCreate ? props.initialValues.recommended_url : ''}
          />
        </Grid.Column>

        <Grid.Column width={4} className="theme-form_field">
          <Field
            component={InputImage}
            name="channel"
            title={t('Channel')}
            validate={isCreate && [validate.required]}
            image={!isCreate ? props.initialValues.channel_url : ''}
          />
        </Grid.Column>

        <Grid.Column width={4} className="theme-form_field">
          <Field
            component={InputImage}
            name="video"
            title={t('Video')}
            validate={isCreate && [validate.required]}
            image={!isCreate ? props.initialValues.video_url : ''}
          />
        </Grid.Column>

        <Grid.Column width={4} className="theme-form_field">
          <Field
            component={InputImage}
            name="history"
            title={t('History')}
            validate={isCreate && [validate.required]}
            image={!isCreate ? props.initialValues.history_url : ''}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4} className="theme-form_field">
          <Field
            component={InputImage}
            name="recommended_tab"
            title={t('Recommended Tablet')}
            validate={isCreate && [validate.required]}
            image={!isCreate ? props.initialValues.recommended_tab_url : ''}
          />
        </Grid.Column>

        <Grid.Column width={4} className="theme-form_field">
          <Field
            component={InputImage}
            name="channel_tab"
            title={t('Channel Tablet')}
            validate={isCreate && [validate.required]}
            image={!isCreate ? props.initialValues.channel_tab_url : ''}
          />
        </Grid.Column>

        <Grid.Column width={4} className="theme-form_field">
          <Field
            component={InputImage}
            name="video_tab"
            title={t('Video Tablet')}
            validate={isCreate && [validate.required]}
            image={!isCreate ? props.initialValues.video_tab_url : ''}
          />
        </Grid.Column>

        <Grid.Column width={4} className="theme-form_field">
          <Field
            component={InputImage}
            name="history_tab"
            title={t('History Tablet')}
            validate={isCreate && [validate.required]}
            image={!isCreate ? props.initialValues.history_tab_url : ''}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4} className="theme-form_field">
          <Field
            component={InputImage}
            name="default"
            title={t('Default')}
            validate={isCreate && [validate.required]}
            image={!isCreate ? props.initialValues.default_url : ''}
          />
        </Grid.Column>

        <Grid.Column width={4} className="theme-form_field">
          <Field
            component={InputImage}
            name="default_tab"
            title={t('Default Tablet')}
            validate={isCreate && [validate.required]}
            image={!isCreate ? props.initialValues.default_tab_url : ''}
          />
        </Grid.Column>

        <Grid.Column width={4} className="theme-form_field">
          <Field
            component={colorPicker}
            name="main_color"
            title={t('Main Color')}
            validate={[validate.required]}
            // color={!isCreate ? props.initialValues.color2 : false}
            // color={{ r: 1, g: 2, b: 3, a: 100 }}
          />
        </Grid.Column>

        <Grid.Column width={4} className="theme-form_field">
          <Field
            component={colorPicker}
            name="second_color"
            title={t('Second Color')}
            validate={[validate.required]}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <Button primary disabled={fetching} onClick={handleSubmit}>
            {t('Save')}
          </Button>
          <Button color="grey" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </React.Fragment>
)

Form.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  validate: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  isCreate: PropTypes.bool,
  errorMessage: PropTypes.string,
  options: PropTypes.object,
}
Form.defaultProps = {
  fetching: false,
  isCreate: false,
  errorMessage: null,
  options: {},
}
export default reduxForm({
  form: 'themes_form',
  enableReinitialize: true,
})(Form)
