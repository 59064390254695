import React from 'react'
import { Route, Switch } from 'react-router-dom'

// base containers
import Login from 'pages/Login'
import About from 'pages/About'
import VideoPlayer from 'pages/VideoPlayer'
import ModulesRouters from './ModulesRouters'

import RedirectRoute from './RedirectRoute'

const isLogged = () => {
  const token = localStorage.getItem('token')
  return !!token
}

const RootRouter = () => (
  <Route>
    <Switch>
      <Route path="/about" component={About} />
      <Route path="/video-player/:video_id" component={VideoPlayer} />
      <RedirectRoute
        path="/login"
        component={Login}
        condition={!isLogged()}
        redirect="/dashboard"
      />

      <RedirectRoute
        path="/"
        component={ModulesRouters}
        condition={isLogged()}
        redirect="/login"
      />
    </Switch>
  </Route>
)
export default RootRouter
