import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  class Update extends React.Component {
    componentWillMount() {
      const { getIPWhitelist, match } = this.props
      getIPWhitelist(match.params.id)
    }

    handleOnClose = () => {
      const { history } = this.props
      history.goBack()
    }

    onSuccess = res => {
      const { history, getIPWhitelists } = this.props
      Toaster.success(res.data.message)
      getIPWhitelists({
        params: { limit: 12 },
      })
      history.goBack()
    }

    handleSubmit = values => {
      const { updateIPWhitelist, match } = this.props
      updateIPWhitelist(values, match.params.id, {
        onFulfilled: this.onSuccess,
      })
    }

    render() {
      const { fetching, error, ip_whitelist } = this.props
      return (
        <WrappedComponent
          fetching={fetching}
          error={error}
          onClose={this.handleOnClose}
          handleSubmit={this.handleSubmit}
          {...this.props}
          ip_whitelist={ip_whitelist.ip_whitelist}
        />
      )
    }
  }

  Update.propTypes = {
    t: PropTypes.func.isRequired,
    getIPWhitelists: PropTypes.func.isRequired,
    getIPWhitelist: PropTypes.func.isRequired,
    ip_whitelist: PropTypes.object,
    match: PropTypes.object,
    fetching: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.func.isRequired,
    updateIPWhitelist: PropTypes.func.isRequired,
  }

  Update.defaultProps = {
    ip_whitelist: {},
    match: {},
    fetching: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    ip_whitelist: api.ip_whitelist || {},
    loading: api.requests.ip_whitelist.put.loading,
    fetching: api.requests.ip_whitelist.get.fetching,
    saving: api.requests.ip_whitelist.put.fetching,
    error: api.requests.ip_whitelist.put.error,
  })

  const mapDispatchToProps = dispatch => ({
    updateIPWhitelist: (data, id, props = {}) =>
      dispatch({
        type: 'UPDATE_IP_WHITELIST',
        data: { ipwhitelist: data },
        vars: { id },
        ...props,
      }),
    getIPWhitelist: (id, props) =>
      dispatch({ type: 'GET_IP_WHITELIST', vars: { id }, ...props }),
    getIPWhitelists: (props = {}) =>
      dispatch({ type: 'GET_IP_WHITELISTS', ...props }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Update))
}

export default HOC
