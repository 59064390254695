import React from 'react'
import PropTypes from 'prop-types'

const colors = {
  active: '#1fc756',
  inactive: '#f2711c',
  pending: '#ad901c',
  custom: '#ad901c',
}
const Status = ({ name }) => (
  <span style={{ color: colors[name], textTransform: 'capitalize' }}>
    {name}
  </span>
)

Status.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Status
