import React, { Component } from 'react'

const HOC = WrappedComponent => {
  class HOCSearch extends Component {
    /*
     *  Handle on typing
     */
    handleOnChange = (e, data) => {
      this.stack = e.target.value
      clearTimeout(this.timer)
      this.timer = setTimeout(this.pushSearchValue, this.interval)
    }

    stack = null
    timer = null
    interval = 700

    /*
     * return search value
     */
    pushSearchValue = () => this.props.onTyping(this.stack)

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleOnChange={this.handleOnChange}
        />
      )
    }
  }

  return HOCSearch
}

export default HOC
