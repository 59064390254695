import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Button, Grid, Message } from 'semantic-ui-react'
import reduxForm from 'atoms/reduxForm'
import textInput from 'molecules/reduxFormFields/textInput'
import TextArea from 'molecules/reduxFormFields/TextArea'
import asyncDropdownInput from 'molecules/reduxFormFields/asyncDropdownInput'

const Form = ({
  handleSubmit,
  validate,
  t,
  fetching,
  errorMessage,
  users,
  fetch_users,
  usersSearch,
}) => (
  <Grid columns={1}>
    {errorMessage && (
      <Grid.Row className="admin-form_error-message">
        <Message negative className="admin-form_error-message">
          {errorMessage}
        </Message>
      </Grid.Row>
    )}
    <Grid.Row>
      <Grid.Column className="admin-form_field">
        <Field
          validate={[validate.required]}
          name="title"
          title={t('Title')}
          component={textInput}
        />
      </Grid.Column>
      <Grid.Column className="admin-form_field">
        <Field
          validate={[validate.required]}
          name="message_en"
          title={t('English Message')}
          component={TextArea}
          placeholder="Enter English Message"
          rows={5}
          style={{ width: '100%' }}
        />
      </Grid.Column>
      <Grid.Column className="admin-form_field">
        <Field
          validate={[validate.required]}
          name="message_ar"
          title={t('Arabic Message')}
          component={TextArea}
          placeholder="Enter Arabic Message"
          rows={5}
          style={{ width: '100%' }}
        />
      </Grid.Column>
      {/*
      <Grid.Column className="admin-form_field">
        <Field
          name="user_id"
          title={t('Select User')}
          component={asyncDropdownInput}
          placeholder={t('Select User')}
          style={{ width: '100%' }}
          clearable
          search
          fluid
          options={users.map(item => {
            return {
              value: item.id,
              text: item.username,
            }
          })}
          onSearch={usersSearch}
          loading={fetch_users}
        />
      </Grid.Column>
      */}

      <Grid.Column className="admin-form_button">
        <Button primary disabled={fetching} onClick={handleSubmit}>
          {t('Send Now')}
        </Button>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

Form.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  validate: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  errorMessage: PropTypes.string,
}
Form.defaultProps = {
  fetching: false,
  errorMessage: null,
}
export default reduxForm({
  form: 'notifications_form',
  enableReinitialize: true,
})(Form)
