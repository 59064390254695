import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'

const Item = ({ icon, text }) => (
  <div>
    <Icon className="tuby-item__icon" name={icon} />
    <span>{text}</span>
  </div>
)

Item.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default Item
