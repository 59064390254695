import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'

const HOC = WrappedComponent => {
  class Show extends React.Component {
    componentWillMount() {
      const { getPage, match } = this.props
      getPage(match.params.id)
    }

    render() {
      const { page } = this.props
      return <WrappedComponent {...this.props} page={page.about_app} />
    }
  }

  Show.propTypes = {
    getPage: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    page: PropTypes.object,
  }

  Show.defaultProps = { page: {} }

  const mapStateToProp = ({ api }) => ({
    page: api.page || {},
    loading: api.requests.page.get.loading,
    fetching: api.requests.page.get.fetching,
    error: api.requests.page.get.error,
  })

  const mapDispatchToProps = dispatch => ({
    getPage: (id, props) =>
      dispatch({
        type: 'GET_PAGE',
        vars: { id },
        ...props,
      }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Show))
}

export default HOC
