import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  class Create extends React.Component {
    componentWillMount() {
      const { getVideosOptions, getChannels } = this.props
      getVideosOptions()
      getChannels({ params: { status: 2 } })
    }

    onSuccess = res => {
      const { history } = this.props
      Toaster.success(res.data.message)
      history.push('/videos')
    }

    handleSubmit = values => {
      const { createVideo } = this.props
      createVideo(values, { onFulfilled: this.onSuccess })
    }

    onCancel = () => {
      const { history } = this.props
      history.goBack()
    }

    onChannesListChange = data => {
      const { getChannels } = this.props
      getChannels({ params: { search: data, status: 2 } })
    }

    render() {
      const { fetching, fetch_channels, error, channels } = this.props
      return (
        <WrappedComponent
          {...this.props}
          channels={channels.channels}
          onChannesListChange={this.onChannesListChange}
          fetching={fetching}
          fetch_channels={fetch_channels}
          error={error}
          handleSubmit={this.handleSubmit}
          onCancel={this.onCancel}
        />
      )
    }
  }

  Create.propTypes = {
    t: PropTypes.func.isRequired,
    createVideo: PropTypes.func.isRequired,
    getVideosOptions: PropTypes.func.isRequired,
    getChannels: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    fetch_channels: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.object.isRequired,
    channels: PropTypes.object,
  }

  Create.defaultProps = {
    fetching: false,
    fetch_channels: false,
    error: null,
    channels: {},
  }

  const mapStateToProp = ({ api }) => ({
    options: api.videos_options || {},
    channels: api.channels || {},
    loading: api.requests.video.post.loading,
    fetching: api.requests.video.post.fetching,
    fetch_channels: api.requests.channels.get.fetching,
    error: api.requests.video.post.error,
  })

  const mapDispatchToProps = dispatch => ({
    createVideo: (data, props = {}) =>
      dispatch({
        type: 'CREATE_VIDEO',
        data: { video: data },
        ...props,
      }),
    getVideosOptions: props =>
      dispatch({
        type: 'GET_VIDEOS_OPTIONS',
        ...props,
      }),
    getChannels: props =>
      dispatch({
        type: 'GET_CHANNELS',
        ...props,
      }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Create))
}

export default HOC
