import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router'
import { Dropdown, Icon } from 'semantic-ui-react'
import { Cards, Card } from 'atoms/Cards'
import ChannelCard from 'templates/ChannelCard'
import Status from 'atoms/Status'

const CustomChannels = ({ channels, handleStatus }) => (
  <Cards gap="13px" cardWidth="300px">
    {channels.map(channel => (
      <Card key={channel.id}>
        <ChannelCard
          image={channel.thumbnail}
          name={channel.title || 'No Title'}
          suggestions={
            channel.suggestions_count === 0
              ? 'No Suggestions'
              : channel.suggestions_count > 1
              ? `${channel.suggestions_count} Suggestions`
              : `${channel.suggestions_count} Suggestion`
          }
          status={<Status name={channel.status_label.toLowerCase()} />}
          actions={
            <Dropdown
              direction="left"
              icon={null}
              trigger={<Icon name="ellipsis horizontal" size="large" />}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="upload"
                  text="Add To Tuby"
                  as={Link}
                  to={`/custom-channels/${channel.id}`}
                />
              </Dropdown.Menu>
            </Dropdown>
          }
        />
      </Card>
    ))}
  </Cards>
)

export default CustomChannels
