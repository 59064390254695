const getData = (res, str) => {
  if (!str) return res
  const keys = str.split('.')
  if (keys.length === 0) return res[str]
  let result = res
  keys.map(key => {
    if (result) result = result[key]
    return null
  })
  return result
}

export default getData
