import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import Item from 'molecules/Item'

const Menu = ({ toggleSidebar, options }) => (
  <div>
    {options.map(item => (
      <NavLink
        className="tuby-menu__item"
        activeClassName="tuby-menu__item--active"
        to={item.url}
        key={item.url}
        onClick={toggleSidebar}
      >
        <Item icon={item.icon} text={item.text} />
      </NavLink>
    ))}
  </div>
)

Menu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default Menu
