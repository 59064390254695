import React, { Component } from 'react'
import PropTypes from 'prop-types'

const HOC = WrappedComponent => {
  class ColorPicker extends Component {
    state = {
      displayColorPicker: false,
    }

    handleClick = () => {
      const { displayColorPicker } = this.state
      this.setState({ displayColorPicker: !displayColorPicker })
    }

    handleClose = () => {
      this.setState({ displayColorPicker: false })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          handleClick={this.handleClick}
          handleClose={this.handleClose}
        />
      )
    }
  }

  ColorPicker.defaultProps = {}
  return ColorPicker
}

export default HOC
