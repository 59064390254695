import React from 'react'
import { Grid } from 'semantic-ui-react'
import Search from 'molecules/Search'

const Filters = ({ disabled, handleFilters }) => (
  <React.Fragment>
    <Grid.Column computer={4} mobile={16}>
      <Search
        disabled={disabled}
        fluid
        size="small"
        icon="search"
        iconPosition="left"
        placeholder="Search by channel name..."
        onTyping={value => handleFilters('search', value)}
      />
    </Grid.Column>
  </React.Fragment>
)

export default Filters
