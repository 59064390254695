import React from 'react'
import { Header, Icon } from 'semantic-ui-react'

const defaultImage =
  'https://c-lj.gnst.jp/public/img/common/noimage.jpg?20181202050038'
const ChannelCard = ({
  name,
  image,
  category,
  videos,
  suggestions,
  status,
  actions,
  onClick,
}) => (
  <div className="channel-card" onClick={onClick}>
    {actions && <div className="channel-card__actions">{actions}</div>}
    <div className="channel-card__image">
      <img
        alt={name}
        className="channel-card__img"
        src={image || defaultImage}
      />
    </div>
    <div className="channel-card__details">
      <Header className="channel-card__title" as="h4">
        {name}
      </Header>
      {category && (
        <div className="channel-card__details--item">{category}</div>
      )}
      {videos && <div className="video-card__details--item">{videos}</div>}
      {suggestions && (
        <div className="video-card__details--item">{suggestions}</div>
      )}
      {status && <div className="video-card__details--item">{status}</div>}
    </div>
  </div>
)

export default ChannelCard
