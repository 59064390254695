import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import swal from 'sweetalert'

const HOC = WrappedComponent => {
  class Show extends Component {
    channel_id = this.props.match.params.channel_id

    componentDidMount() {
      this.props.getChannel(this.channel_id)
    }

    handleDelete = () => {
      const { deleteChannel, history } = this.props

      swal({
        title: 'Are you sure?',
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          catch: {
            text: 'Yes, Delete',
            closeModal: false,
          },
        },
      })
        .then(confirm => {
          if (!confirm) throw null
          return new Promise((resolve, reject) => {
            const onFulfilled = respo => {
              resolve(respo.data.message)
              setTimeout(function() {
                history.push('/channels')
              }, 2000)
            }

            const onFaild = res => {
              reject(res)
            }
            deleteChannel(this.channel_id, { onFulfilled, onFaild })
          })
        })
        .then(res => {
          swal({
            title: 'Deleted!',
            text: res,
            icon: 'success',
            button: false,
            timer: 2000,
          })
        })
        .catch(err => {
          if (err) {
            swal({
              title: 'Error',
              text: err,
              icon: 'error',
              button: false,
            })
          } else {
            swal.stopLoading()
            swal.close()
          }
        })
    }

    render() {
      console.log('channelid', this.props)
      return (
        <WrappedComponent
          {...this.props}
          channel_id={this.channel_id}
          handleDelete={this.handleDelete}
        />
      )
    }
  }

  const mapStateToProps = ({ api }) => ({
    channel: api.channel || {},
    fetching: api.requests.channel.get.fetching,
    error: api.requests.channel.get.error,
  })

  const mapDispatchToProps = dispatch => ({
    getChannel: (channel_id, props) =>
      dispatch({
        type: 'GET_CHANNEL',
        vars: { channel_id },
        ...props,
      }),
    deleteChannel: (id, props) =>
      dispatch({
        type: 'DELETE_CHANNEL',
        vars: { id },
        ...props,
      }),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(translate('translations')(Show))
}

export default HOC
