import React from 'react'
import { Switch, Route } from 'react-router-dom'

import List from './List'
import Create from './Create'
import Edit from './Edit'
import Show from './Show'

const Routes = () => (
  <Switch>
    <Route path="/channels/create" component={Create} />
    <Route path="/channels/:id/edit" component={Edit} />
    <Route path="/channels/:channel_id/show" component={Show} />
    <Route path="/channels" component={List} />
  </Switch>
)

export default Routes
