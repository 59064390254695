import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  class Create extends Component {
    handleOnClose = () => {
      this.props.history.goBack()
    }

    onSuccess = res => {
      Toaster.success(res.data.message)
      this.props.getNotifications({
        params: { limit: 12 },
      })
      this.props.history.goBack()
    }

    handleSubmit = values => {
      this.props.createNotification(values, { onFulfilled: this.onSuccess })
    }

    handleUsersSearch = data => {
      const { getUsers } = this.props
      getUsers({ params: { search: data } })
    }

    componentDidMount() {
      this.props.getUsers({
        params: { limit: 7 },
      })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          fetching={this.props.fetching}
          error={this.props.error}
          onClose={this.handleOnClose}
          handleSubmit={this.handleSubmit}
          users={this.props.users.users || []}
          usersSearch={this.handleUsersSearch}
        />
      )
    }
  }

  Create.propTypes = {
    t: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.func.isRequired,
    createNotification: PropTypes.func.isRequired,
  }

  Create.defaultProps = {
    fetching: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    loading: api.requests.notification.post.loading,
    fetching: api.requests.notification.post.fetching,
    error: api.requests.notification.post.error,
    users: api.users || {},
    fetch_users: api.requests.users.get.fetching,
  })

  const mapDispatchToProps = dispatch => ({
    createNotification: (data, props = {}) =>
      dispatch({
        type: 'CREATE_NOTIFICATION',
        data: { notification: data },
        ...props,
      }),

    getNotifications: props =>
      dispatch({ type: 'GET_NOTIFICATIONS', ...props }),

    getUsers: props =>
      dispatch({
        type: 'GET_USERS',
        ...props,
      }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Create))
}

export default HOC
