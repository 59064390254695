import React from 'react'
import { Header, Icon } from 'semantic-ui-react'

const defaultImage =
  'https://c-lj.gnst.jp/public/img/common/noimage.jpg?20181202050038'
const ThemeCard = ({
  image,
  title_en,
  title_ar,
  color,
  status,
  actions,
  onClick,
}) => (
  <div className="theme-card" onClick={onClick}>
    {actions && <div className="theme-card__actions">{actions}</div>}
    <div className="theme-card__image">
      <img
        alt={title_en}
        className="theme-card__img"
        src={image || defaultImage}
      />
    </div>
    <div className="theme-card__details">
      <Header className="theme-card__title" as="h4">
        {title_en}
      </Header>
      {title_ar && <div className="theme-card__details--item">{title_ar}</div>}
      {color && <div className="theme-card__details--item">{color}</div>}
      {status && <div className="theme-card__details--item">{status}</div>}
    </div>
  </div>
)

export default ThemeCard
