import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import Actions from '../Actions'
import { getPageURL } from '../../../../functions/utilities'

const HOC = WrappedComponent => {
  class Pages extends Component {
    state = {
      filters: {
        page: 1,
        limit: 20,
      },
    }

    loadPages = (filters = this.state.filters) => {
      this.props.getPages({ params: filters })
    }

    componentDidMount() {
      this.loadPages()
    }

    onEdit = id => {
      this.props.history.go(`/pages/${id}/edit`)
    }

    onShow = id => {
      this.props.history.go(`/pages/${id}/show`)
    }

    render() {
      const { t } = this.props
      const headers = [
        {
          name: t('English Title'),
          render: row => (
            <a target="_blank" href={getPageURL(row.about_app_type_label)}>
              {row.title_en}
            </a>
          ),
        },
        {
          name: t('Arabic Title'),
          render: row => (
            <a
              target="_blank"
              href={`${getPageURL(row.about_app_type_label)}/ar`}
            >
              {row.title_ar}
            </a>
          ),
        },
        {
          name: t('Actions'),
          render: row => (
            <Actions
              id={row.id}
              onEdit={() => this.onEdit(row.id)}
              onShow={() => this.onShow(row.id)}
            />
          ),
          props: { width: 2 },
        },
      ]
      const pages = this.props.pages.about_apps || []
      return (
        <WrappedComponent {...this.props} pages={pages} headers={headers} />
      )
    }
  }

  const mapStateToProps = ({ api }) => ({
    pages: api.pages || {},
    fetching: api.requests.pages.get.fetching,
    error: api.requests.pages.get.error,
  })

  const mapDispatchToProps = dispatch => ({
    getPages: props =>
      dispatch({
        type: 'GET_PAGES',
        ...props,
      }),
  })
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(translate('translations')(Pages))
}

export default HOC
