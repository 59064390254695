import React from 'react'
import { Image, Grid, List, Header, Segment, Icon } from 'semantic-ui-react'
import numberFixed from '../../../../../functions/numberFixed'

const TopVideos = ({
  viewsByCategory,
  noViewsByCategory,
  fetching,
  loading,
  error,
}) => (
  <Grid.Column mobile={16} tablet={16} computer={8}>
    <Segment raised>
      <Header as="h4"> Number of tuby users views by category </Header>
      <List divided verticalAlign="middle">
        {viewsByCategory.map((item, index) => (
          <List.Item key={item.id}>
            <List.Content floated="right">
              <List.Header>{numberFixed(item.views)}</List.Header>
              <List.Description>views</List.Description>
            </List.Content>
            <Image
              avatar
              src="https://cdn.onlinewebfonts.com/svg/img_318548.png"
            />

            {/* <Icon size="big" name="qrcode" /> */}
            <List.Content>
              <List.Header>{item.category_name}</List.Header>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </Segment>
  </Grid.Column>
)

export default TopVideos
