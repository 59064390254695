import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  class Create extends React.Component {
    componentWillMount() {
      const { getThemeOptions } = this.props
      // getThemeOptions()
    }

    onSuccess = res => {
      const { history, getThemes } = this.props
      Toaster.success(res.data.message)
      getThemes()
      history.goBack()
    }

    getFormData = values => {
      const formData = new FormData()
      Object.keys(values).forEach(key => formData.append(key, values[key]))
      return formData
    }

    handleSubmit = values => {
      const { createTheme } = this.props
      createTheme(values, { onFulfilled: this.onSuccess })
    }

    onCancel = () => {
      const { history } = this.props
      history.goBack()
    }

    render() {
      const { fetching, error } = this.props
      return (
        <WrappedComponent
          {...this.props}
          fetching={fetching}
          error={error}
          handleSubmit={this.handleSubmit}
          onCancel={this.onCancel}
        />
      )
    }
  }

  Create.propTypes = {
    t: PropTypes.func.isRequired,
    createTheme: PropTypes.func.isRequired,
    getThemeOptions: PropTypes.func.isRequired,
    getThemes: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.object.isRequired,
  }

  Create.defaultProps = {
    fetching: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    options: api.themes_options || {},
    loading: api.requests.theme.post.loading,
    fetching: api.requests.theme.post.fetching,
    error: api.requests.theme.post.error,
  })

  const mapDispatchToProps = dispatch => ({
    createTheme: (data, props = {}) =>
      dispatch({
        type: 'CREATE_THEME',
        data: { theme: data },
        ...props,
      }),
    getThemeOptions: props =>
      dispatch({
        type: 'GET_THEMES_OPTIONS',
        ...props,
      }),
    getThemes: props =>
      dispatch({
        type: 'GET_THEMES',
        ...props,
      }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Create))
}

export default HOC
