import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Header } from 'semantic-ui-react'

const VideoModal = ({ active, handleClose, video_id, title }) => (
  <Modal dimmer="blurring" open={active} onClose={handleClose}>
    <Header icon="play circle outline" content={title} />
    <Modal.Content className="video-modal" style={{ padding: 0 }}>
      <video
        className="video-card__img"
        controls
        autoPlay
        width="100%"
        height="500"
        src={video_id}
        loop
        muted
      >
        <source src={video_id} type="video/mp4" />
      </video>
    </Modal.Content>
  </Modal>
)

VideoModal.defaultProps = {
  video: {},
}
export default VideoModal
