import React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Icon } from 'semantic-ui-react'
import { Cards, Card } from 'atoms/Cards'
import IntroVideoCard from 'templates/IntroVideoCard'
import Status from 'atoms/Status'

const IntroVideos = ({ intro_videos, handleDelete, handleStatus }) => (
  <Cards gap="13px" cardWidth="300px">
    {intro_videos.map(intro_video => (
      <Card key={intro_video.id}>
        <IntroVideoCard
          video={intro_video.video_url}
          title_en={intro_video.title_en || 'No Title'}
          title_ar={intro_video.title_ar || 'No Title'}
          status={<Status name={intro_video.status_label.toLowerCase()} />}
          actions={
            <Dropdown
              direction="left"
              icon={null}
              trigger={<Icon name="ellipsis horizontal" size="large" />}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="pencil alternate"
                  text="Edit"
                  as={Link}
                  to={`/intro-videos/${intro_video.id}/edit`}
                />
                {intro_video.status === 2 && (
                  <Dropdown.Item
                    icon="eye slash"
                    text="Deactive"
                    onClick={() => handleStatus(intro_video.id, 1)}
                  />
                )}
                {intro_video.status !== 2 && (
                  <Dropdown.Item
                    icon="check"
                    text="Active"
                    onClick={() => handleStatus(intro_video.id, 2)}
                  />
                )}
                <Dropdown.Item
                  icon="trash alternate outline"
                  text="Delete"
                  onClick={() => handleDelete(intro_video.id)}
                />
              </Dropdown.Menu>
            </Dropdown>
          }
        />
      </Card>
    ))}
  </Cards>
)

export default IntroVideos
