import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Toaster from 'toastr'

const HOC = WrappedComponent => {
  class Update extends React.Component {
    componentWillMount() {
      const { getUser, match } = this.props
      getUser(match.params.id)
    }

    handleOnClose = () => {
      const { history } = this.props
      history.goBack()
    }

    onSuccess = res => {
      const { history, getUsers } = this.props
      Toaster.success(res.data.message)
      getUsers({
        params: { limit: 12 },
      })
      history.goBack()
    }

    handleSubmit = values => {
      const { updateUser, match } = this.props
      updateUser(values, match.params.id, { onFulfilled: this.onSuccess })
    }

    render() {
      const { fetching, error, user } = this.props
      return (
        <WrappedComponent
          fetching={fetching}
          error={error}
          onClose={this.handleOnClose}
          handleSubmit={this.handleSubmit}
          {...this.props}
          user={user.user}
        />
      )
    }
  }

  Update.propTypes = {
    t: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    user: PropTypes.object,
    match: PropTypes.object,
    fetching: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
  }

  Update.defaultProps = {
    user: {},
    match: {},
    fetching: false,
    error: null,
  }

  const mapStateToProp = ({ api }) => ({
    user: api.user || {},
    loading: api.requests.user.put.loading,
    fetching: api.requests.user.get.fetching,
    saving: api.requests.user.put.fetching,
    error: api.requests.user.put.error,
  })

  const mapDispatchToProps = dispatch => ({
    updateUser: (data, id, props = {}) =>
      dispatch({
        type: 'UPDATE_USER',
        data: { user: data },
        vars: { id },
        ...props,
      }),
    getUser: (id, props) =>
      dispatch({ type: 'GET_USER', vars: { id }, ...props }),
    getUsers: (id, props = {}) => dispatch({ type: 'GET_USERS', ...props }),
  })

  return connect(
    mapStateToProp,
    mapDispatchToProps,
  )(translate('translations')(Update))
}

export default HOC
