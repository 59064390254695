import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader, Message } from 'semantic-ui-react'
import Modal from '../../../atoms/Modal'

const Show = ({ ip_whitelist, onClose, error, fetching, t }) => (
  <Modal
    size="tiny"
    open
    close={onClose}
    headerIcon="unlock alternate"
    headerContent="IP Whitelist"
  >
    {fetching && (
      <Dimmer inverted active>
        <Loader size="medium">Fetching IP Whitelist data...</Loader>
      </Dimmer>
    )}
    <table className="admin-details-table">
      <tbody>
        {error ? (
          <tr>
            <th colSpan={2}>
              <Message negative>{error}</Message>
            </th>
          </tr>
        ) : (
          <Fragment>
            <tr>
              <th className="admin-details-table_th">{t('Start IP')}</th>
              <td>{ip_whitelist.start_ip}</td>
            </tr>
            <tr>
              <th className="admin-details-table_th">{t('End IP')}</th>
              <td>{ip_whitelist.end_ip}</td>
            </tr>
          </Fragment>
        )}
      </tbody>
    </table>
  </Modal>
)

export default Show

Show.propTypes = {
  t: PropTypes.func.isRequired,
  ip_whitelist: PropTypes.object,
  fetching: PropTypes.bool,
  error: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

Show.defaultProps = {
  ip_whitelist: {},
  fetching: false,
  error: null,
}
