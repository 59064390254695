import React from 'react'
import PropTypes from 'prop-types'
import { Input as SemInput } from 'semantic-ui-react'

const Input = ({ icon, error, ...rest }) => (
  <div className="bricks-sem-input">
    <SemInput error={!!error} {...rest}>
      {icon}
      <input />
    </SemInput>
    {error && <div className="bricks-sem-input__error-msg">{error}</div>}
  </div>
)

Input.propTypes = {
  icon: PropTypes.any,
  error: PropTypes.any,
}

Input.defaultProps = {
  icon: null,
  error: false,
}

export default Input
