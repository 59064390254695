import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Header, Button, Icon, Dimmer, Loader } from 'semantic-ui-react'

import AppContainer from '../../../templates/AppContainer'
import ThemeInfo from './Widgets/ThemeInfo'

const themeStatus = {
  0: { label: 'Active', icon: 'eye' },
  1: { label: 'Active', icon: 'eye' },
  2: { label: 'Deactive', icon: 'eye slash' },
}
const Show = ({ theme, fetching, t, theme_id, handleDelete }) => (
  <div style={{ position: 'relative' }}>
    {console.log('theme_id', theme_id)}
    {console.log('theme', theme.id)}
    <AppContainer
      header={t('Theme Details')}
      button={
        theme_id != 1 && (
          <div>
            <Button
              icon="trash alternate"
              basic
              color="red"
              content="Delete"
              onClick={handleDelete}
            />
            <Button
              icon="pencil alternate"
              basic
              color="violet"
              content="Edit"
              as={Link}
              to={`/themes/${theme_id}/edit`}
            />
          </div>
        )
      }
    >
      {fetching && (
        <Dimmer inverted active>
          <Loader size="medium">Fetching theme data...</Loader>
        </Dimmer>
      )}
      <Grid divided="vertically">
        <Grid.Row columns={2}>
          <Grid.Column width={16}>
            <ThemeInfo theme={theme} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppContainer>
  </div>
)

export default Show
